import {
  SET_CUISINES,
  ADD_RESTAURANTS_TO_CUISINES,
  UPDATE_CUISINES
} from "../actions/types";

const initialState = {
  cuisinesUpdated: "none",
  cuisines: [],
  cuisinesFiltered: [],
  cuisinesFilters: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CUISINES:
      return {
        ...state,
        cuisines: action.payload,
        cuisinesUpdated: "SET_CUISINES"
      };

    case ADD_RESTAURANTS_TO_CUISINES:
      const newCuisines = state.cuisines.map(cuisine => {
        if (cuisine.id === action.payload.cuisine.id) {
          return {
            ...action.payload.cuisine,
            restaurants: cuisine.restaurants.concat(
              action.payload.cuisine.restaurants
            )
          };
        } else {
          return cuisine;
        }
      });

      return {
        ...state,
        cuisines: newCuisines,
        cuisinesUpdated: "ADD_RESTAURANTS_TO_CUISINES"
      };

    case UPDATE_CUISINES:
      return {
        ...state,
        cuisinesUpdated: action.payload
      };

    default:
      return state;
  }
}
