import axios from "axios";
import { USERS_SET_USERS } from "./types";

export const getAllUsers = () => async dispatch => {
  try {
    const response = await axios.get("/api/v1/users");

    dispatch({
      type: USERS_SET_USERS,
      payload: { users: response.data.users }
    });
  } catch (e) {
    console.log(e);
  }
};
