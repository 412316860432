import React, { useState } from "react";

import { connect } from "react-redux";
import { setRestaurantForm } from "../../../../actions/restaurantActions";

import { Modal, Input, Form, Icon, Select, Checkbox, Tag, message } from "antd";
import CustomButton from "../../../common/CustomButton";

const socials = {
  email: {
    id: "email",
    name: "Email"
  },
  twitter: {
    id: "twitter",
    name: "Twitter"
  },
  website: {
    id: "website",
    name: "Website"
  }
};

const ModalNewRestaurantSocials = ({
  restaurant,
  setRestaurantForm,
  ...rest
}) => {
  const [newSocial, setNewSocial] = useState({
    name: "",
    url: ""
  });

  const handleChangeSelect = name => value => {
    setNewSocial({
      ...newSocial,
      [name]: value
    });
  };

  const handleChange = e => {
    setNewSocial({
      ...newSocial,
      [e.target.name]: e.target.value
    });
  };

  const addSocial = e => {
    const newRestaurant = { ...restaurant };
    if (newSocial.name === "" || newSocial.url === "") {
      message.warning("Please fill all the inputs");
      return;
    }

    newRestaurant.socials.push(newSocial);

    setRestaurantForm(newRestaurant);
  };

  const removeSocial = index => e => {
    const newRestaurant = { ...restaurant };
    delete newRestaurant.socials[index];

    setRestaurantForm(newRestaurant);
  };

  return (
    <div className="column column-social-header border-r">
      <Form.Item label="Website & Social Media" className="item">
        <Select
          size="large"
          className="select-social"
          defaultValue={"Select"}
          onChange={handleChangeSelect("name")}
        >
          {Object.values(socials).map(social => (
            <Select.Option key={social.id} value={social.id}>
              {social.name}
            </Select.Option>
          ))}
        </Select>
        <Input
          type="text"
          className="input-social"
          name="url"
          onChange={handleChange}
        />
        <CustomButton type="primary" onClick={addSocial}>
          Add
        </CustomButton>
      </Form.Item>

      <ul className="socials-list">
        {restaurant.socials &&
          restaurant.socials.map((social, index) => (
            <li>
              <span>
                <strong>{social.name}</strong>
              </span>
              <span>
                <a href={social.url}>{social.url}</a>
              </span>
              <CustomButton
                type="tertiary"
                className="btn-icon"
                onClick={removeSocial(index)}
              >
                <Icon type="close" />
              </CustomButton>
            </li>
          ))}
      </ul>
    </div>
  );
};

const mapStateToProps = state => ({
  restaurant: state.restaurant.restaurantForm
});

export default connect(
  mapStateToProps,
  { setRestaurantForm }
)(ModalNewRestaurantSocials);
