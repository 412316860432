import React, { Component } from "react";
import GoogleMapReact from "google-map-react";

import axios from "axios";

import "./RestaurantMap.less";

class RestaurantMap extends Component {
  state = {
    marker: {}
  };

  handleApiLoaded = (map, maps) => {
    const request = {
      query: this.props.location.address + " Kingston",
      fields: ["name", "geometry"]
    };

    const service = new maps.places.PlacesService(map);

    service.findPlaceFromQuery(request, (results, status) => {
      if (status === maps.places.PlacesServiceStatus.OK) {
        map.setCenter(results[0].geometry.location);

        createMarker(results[0]);

        this.setState({
          marker: {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          }
        });
      }
    });

    function createMarker(place) {
      var marker = new maps.Marker({
        map: map,
        position: place.geometry.location
      });

      maps.event.addListener(marker, "click", function() {
        //infowindow.setContent(place.name);
        //infowindow.open(map, this);
      });
    }
  };

  render() {
    const { marker } = this.state;

    return (
      <div className="map-container">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyCLVJuhIW75Ujg-fRjhqnZFMWDuYw9mPeI",
            libraries: "places"
          }}
          defaultCenter={{
            lat: 44.22976,
            lng: -76.48098
          }}
          defaultZoom={16}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
        />
      </div>
    );
  }
}

export default RestaurantMap;
