import React, { Component } from "react";

import { connect } from "react-redux";
import {
  updateRestaurant,
  setRestaurantForm
} from "../../../../actions/restaurantActions";
import { getCuisines } from "../../../../actions/cuisineActions";

import { Modal, Input, Form, Icon, Select, Checkbox, Tag, message } from "antd";

import CustomButton from "../../../common/CustomButton";
import "./ModalNewRestaurant.less";

import ModalNewRestaurantSocial from "./ModalNewRestaurantSocials";
import ModalNewRestaurantCuisines from "./ModalNewRestaurantCuisines";

class ModalNewRestaurant extends Component {
  state = {
    loading: false,
    cuisines: []
  };

  handleOk = () => {
    this.props.updateRestaurant(this.props.restaurant);
    //this.setState({ loading: true });
  };

  handleCancel = () => {
    this.props.showModal();
  };

  handleChange = e => {
    const newRestaurant = { ...this.props.restaurant };

    const keys = e.target.name.split(".");
    let val = newRestaurant;

    for (let i = 0; i < keys.length - 1; i++) {
      val = val[keys[i]];
    }

    val[keys[keys.length - 1]] = e.target.value;

    this.props.setRestaurantForm(newRestaurant);
  };

  handleChangeSelect = name => value => {
    this.props.setRestaurantForm({ ...this.props.restaurant, [name]: value });
  };

  handleRemoveFromArray = (name, id) => {
    let array = [...this.state.restaurant[name]];
    array.splice(id, 1);

    this.props.setRestaurantForm({ ...this.props.restaurant, [name]: array });
  };

  handleAddLocation = () => {
    const newLocation = {
      phone: "",
      location: {
        address: "",
        city: "",
        postal_code: ""
      }
    };

    this.props.setRestaurantForm({
      ...this.props.restaurant,
      restaurant_branches: [
        ...this.props.restaurant.restaurant_branches,
        newLocation
      ]
    });
  };

  handleRemoveLocation = id => {
    let restaurant_branches = [...this.props.restaurant.restaurant_branches];
    restaurant_branches.splice(id, 1);

    this.props.setRestaurantForm({
      ...this.props.restaurant,
      restaurant_branches
    });
  };

  componentDidMount() {
    this.props.getCuisines();
  }

  componentDidUpdate(prevProps) {
    if (this.props.cuisines !== this.state.cuisines) {
      this.setState({
        cuisines: this.props.cuisines
      });
    }

    if (this.props.loading !== this.state.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  render() {
    const { loading, cuisines } = this.state;
    const { visible, restaurant } = this.props;

    const title = (
      <div className="dashboard-modal-form-title">
        <h2>Add New Restaurant</h2>
        <span>
          Add restaurant with one or more locations, set cuisine to help patrons
          discover you & add other useful info
        </span>
      </div>
    );

    return (
      <div>
        <Modal
          visible={visible}
          title="Title"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          wrapClassName="dashboard-modal"
          centered={true}
          title={title}
          width={850}
          bodyStyle={{
            padding: 0
          }}
          footer={
            <div className="dashboard-modal-form-footer">
              <CustomButton
                key="back"
                type="secondary"
                onClick={this.handleCancel}
                className="btn-text"
              >
                Don't Save & Close
              </CustomButton>
              <CustomButton
                key="submit"
                type="primary"
                loading={loading}
                onClick={this.handleOk}
                className="btn-text"
              >
                Save
              </CustomButton>
            </div>
          }
        >
          <Form
            layout="vertical"
            className="dashboard-modal-form dashboard-modal-form-restaurant"
          >
            <div className="row row-restaurant-header border-b">
              <Form.Item
                label="Restaurant Name"
                className="item item-restaurant-name"
              >
                <Input
                  type="text"
                  placeholder="Restaurant Name"
                  name="name"
                  value={restaurant.name}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item className="item">
                <CustomButton
                  type="primary"
                  className="btn-text"
                  onClick={this.handleAddLocation}
                >
                  Add Location
                </CustomButton>
              </Form.Item>
              <Form.Item
                label="Featured Listing"
                className="item item-featured-listing"
              >
                $
                <Input
                  type="text"
                  placeholder="10"
                  name="featured_listing"
                  value={restaurant.featured_listing}
                  onChange={this.handleChange}
                />
                monthly
              </Form.Item>
              <Form.Item
                label="Listing Status"
                className="item item-listing-status"
              >
                <Select
                  size="large"
                  className="select-city"
                  name="listing_status"
                  value={restaurant.listing_status}
                  onChange={this.handleChangeSelect("listing_status")}
                >
                  <Select.Option value="online">Online</Select.Option>
                  <Select.Option value="pending">Pending</Select.Option>
                  <Select.Option value="archived">Archived</Select.Option>
                </Select>
              </Form.Item>
            </div>

            <div className="row row-location-header border-b">
              <span>City</span>
              <span>Address</span>
              <span>Postal Code</span>
              <span>Phone</span>
            </div>

            {restaurant.restaurant_branches &&
              restaurant.restaurant_branches.map((branch, id) => (
                <div className="row row-location border-b">
                  <Form.Item className="item">
                    <Input
                      type="text"
                      placeholder="City"
                      name={`restaurant_branches.${id}.location.city`}
                      value={branch.location.city}
                      onChange={this.handleChange}
                    />
                  </Form.Item>
                  <Form.Item className="item">
                    <Input
                      type="text"
                      placeholder="Address"
                      name={`restaurant_branches.${id}.location.address`}
                      value={branch.location.address}
                      onChange={this.handleChange}
                    />
                  </Form.Item>
                  <Form.Item className="item">
                    <Input
                      type="text"
                      placeholder="Postal Code"
                      name={`restaurant_branches.${id}.location.postal_code`}
                      value={
                        restaurant.restaurant_branches &&
                        branch.location.postal_code
                      }
                      onChange={this.handleChange}
                    />
                  </Form.Item>
                  <Form.Item className="item">
                    <Input
                      type="text"
                      placeholder="Phone Number"
                      name={`restaurant_branches.${id}.phone`}
                      value={restaurant.restaurant_branches && branch.phone}
                      onChange={this.handleChange}
                    />
                  </Form.Item>

                  {id >= 1 ? (
                    <Form.Item className="item">
                      <CustomButton
                        type="tertiary"
                        className="btn-icon"
                        onClick={() => this.handleRemoveLocation(id)}
                      >
                        <Icon type="close" />
                      </CustomButton>
                    </Form.Item>
                  ) : (
                    <span />
                  )}
                </div>
              ))}

            <ModalNewRestaurantCuisines />

            <div className="row row-bottom border-b">
              <ModalNewRestaurantSocial />

              {/*}
              <div className="column column-extra-info">
                <Form.Item className="item border-b">
                  <Checkbox>Live Music</Checkbox>
                  <div className="item-data">
                    <Input type="text" placeholder="When" />
                  </div>
                </Form.Item>
                <Form.Item className="item border-b">
                  <Checkbox>Reservations</Checkbox>
                  <div className="item-data-reservations">
                    <div className="item-data-row">
                      <Input
                        type="text"
                        className="input-number"
                        defaultValue="0"
                      />
                      <span>to</span>
                      <Input
                        type="text"
                        className="input-number"
                        defaultValue="0"
                      />
                      <span>people</span>
                    </div>
                    <div className="item-data-row">
                      <Input
                        type="text"
                        placeholder="18% automatic gratuity on groups of 6"
                      />
                    </div>
                  </div>
                </Form.Item>
                <Form.Item className="item border-b">
                  <Checkbox>Delivery</Checkbox>
                  <div className="item-data">
                    <Input
                      type="text"
                      placeholder="$4 delivery fee, free if order over $50"
                    />
                  </div>
                </Form.Item>
                <Form.Item className="item border-b">
                  <Checkbox>Take out</Checkbox>
                  <div className="item-data">
                    <Input
                      type="text"
                      placeholder="Take out pickup directions"
                    />
                  </div>
                </Form.Item>

                <Form.Item className="item border-b">
                  <Checkbox>Dine in</Checkbox>
                  <div className="item-data">
                    <Input
                      type="text"
                      placeholder="Describe the seating you have"
                    />
                  </div>
                </Form.Item>
                <Form.Item className="item border-b">
                  <Checkbox>Catering</Checkbox>
                  <div className="item-data">
                    <Input
                      type="text"
                      placeholder="Contact us to discuss our catering options"
                    />
                  </div>
                </Form.Item>
                <Form.Item className="item border-b">
                  <Checkbox>Outdoor/Patio</Checkbox>
                  <div className="item-data">
                    <Input
                      type="text"
                      placeholder="Large outdoor areas that can seat 50"
                    />
                  </div>
                </Form.Item>
                <Form.Item className="item border-b">
                  <Checkbox>Event Space</Checkbox>
                  <div className="item-data">
                    <Input
                      type="text"
                      placeholder="Contact us to ask about booking our space"
                    />
                  </div>
                </Form.Item>
              </div>
            
                */}
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  restaurant: state.restaurant.restaurantForm,
  cuisines: state.cuisine.cuisines,
  loading: state.layout.loading
});

export default connect(
  mapStateToProps,
  { updateRestaurant, getCuisines, setRestaurantForm }
)(ModalNewRestaurant);
