import React from "react";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import setAuthToken from "./utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { setCurrentUser, logoutUser } from "./actions/authActions";

import ReactGA from "react-ga";

import { Icon } from "antd";

import Landing from "./components/pages/landing/Landing";
import Dashboard from "./components/pages/dashboard/Dashboard";
import Restaurant from "./components/pages/RestaurantMenu/Restaurant";

import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";

import PrivateRoute from "./components/common/PrivateRoute";

import "./_var.less";
import "./App.less";
import "./App.css";

if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode the token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());

    window.location.href = "/";
  }
}

ReactGA.initialize("UA-10864369-1");

class App extends React.Component {
  state = {
    headerHeight: 0,
    loading: true
  };

  setHeaderHeight = val => {
    this.setState({
      headerHeight: val
    });
  };

  componentDidUpdate() {
    document.getElementById("loading-screen").classList.add("hide");
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <Header setHeaderHeight={this.setHeaderHeight} />
          <div style={{ paddingTop: this.state.headerHeight, overflowX: "hidden" }}>
            <Switch>
              <Route exact path="/" component={Landing} />
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <Route exact path="/menus/:slug" component={Restaurant} />
            </Switch>
          </div>
          <Footer />
        </Router>
      </Provider>
    );
  }
}
export default App;
