import cloneDeep from "lodash.clonedeep";

export const reorder = (list, startIndex, endIndex) => {
  const temporal = Array.from(list);
  const [removed] = temporal.splice(startIndex, 1);
  temporal.splice(endIndex, 0, removed);

  const result = temporal.map((el, id) => {
    if (typeof el === "object") {
      el.position = id;
    }

    return el;
  });

  return result;
};

export const reverse = (list) => {
  const temporal = Array.from(list.reverse());

  const result = temporal.map((el, id) => {
    if (typeof el === "object") {
      el.position = id;
    }

    return el;
  });

  return result;
};

export const valuesOrdered = (object) => {
  return orderArrayFromPosition(Object.values(cloneDeep(object)));
};

export const orderArrayFromPosition = (array) => {
  return array.sort((a, b) => {
    if (typeof a.position === "string") a.position = parseInt(a.position);
    if (typeof b.position === "string") b.position = parseInt(b.position);

    return a.position < b.position ? -1 : 1;
  });
};

export const prepareInformation = (object, type) => {
  const array = valuesOrdered(object);
  array.forEach((a, index) => (a.position = index + 1));
  const response = {
    errors: [],
    result: object,
  };

  for (let item of array) {
    if (type === "menu") {
      if (!item.children.length) {
        response.errors.push("One menu has not any categories");
      }
    } else if (type === "category") {
      if (!item.children.length) {
        response.errors.push("One category has not any items");
      }
    }
  }

  return response;
};

export const filterCuisines = (cuisines, filters) => {
  filters = cloneDeep(filters);
  cuisines = cloneDeep(cuisines);

  let onlyOpen = false;
  Object.keys(filters).forEach((el) => {
    if (el === "open" && filters.open) {
      delete filters.open;
      onlyOpen = true;
      return true;
    }
    return false;
  });

  return cuisines.filter((cuisine) => {
    if (!cuisine.restaurants || !cuisine.restaurants.length) return false;

    if (Object.keys(filters).length && !filters[cuisine.slug]) return false;

    cuisine.restaurants = cuisine.restaurants.filter((restaurant) => {
      if (
        onlyOpen &&
        restaurant.open_schedule &&
        !restaurant.open_schedule.open
      ) {
        return false;
      }
      return true;
    });

    if (!cuisine.restaurants.length) return false;

    return true;
  });
};

export const filterUsers = (users, tab) => {
  switch (tab) {
    case "all":
      return users;
    case "users":
      return users.filter((el) => el.roles.length === 0);
    case "owners_managers":
      return users.filter((el) =>
        el.roles.find((el) => el.slug === "owner" || el.slug === "manager")
      );
    case "admins":
      return users.filter((el) =>
        el.roles.find((el) => el.slug === "admin" || el.slug === "master-admin")
      );
  }
};

export const reverseAll = ({ menus, categories, items }) => {
  menus = cloneDeep(menus);
  categories = cloneDeep(categories);
  items = cloneDeep(items);

  const response = {
    menus: [],
    categories: [],
    items: [],
  };

  response.menus = reverse(orderArrayFromPosition(menus));

  menus.forEach((menu) => {
    let menuCategories = categories.filter((el) =>
      menu.children.includes(el.id)
    );
    menuCategories = reverse(orderArrayFromPosition(menuCategories));
    menu.children = menuCategories.map((el) => el.id);
    response.categories = response.categories.concat(menuCategories);

    menuCategories.forEach((category) => {
      let categoryItems = items.filter((el) =>
        category.children.includes(el.id)
      );
      categoryItems = reverse(orderArrayFromPosition(categoryItems));
      category.children = categoryItems.map((el) => el.id);
      response.items = response.items.concat(categoryItems);
    });
  });

  return response;
};
