import React, { useState } from "react";

import { connect } from "react-redux";
import { setLayoutModal } from "../../../actions/layoutActions";

import { valuesOrdered } from "../../../utils/array";

import EditCategoriesModal from "./EditCategoriesModal";
import EditItemsModal from "./EditItemsModal";
import CustomButton from "../../common/CustomButton";

import "./RestaurantMenu.less";

const RestaurantMenu = ({
  menus,
  categories,
  items,
  options,
  searchQuery,
  editing,
  selectedMenu,
  // actions
  setLayoutModal,
  ...rest
}) => {
  const openCategoriesModal = menuId => {
    setLayoutModal({
      type: "MODAL_MENU_CATEGORIES",
      id: menuId
    });
  };

  const openItemsModal = catId => {
    setLayoutModal({
      type: "MODAL_MENU_ITEMS",
      id: catId
    });
  };

  let filteredMenus = menus;

  if (selectedMenu !== "all") {
    filteredMenus = {
      [selectedMenu]: filteredMenus[selectedMenu]
    };
  }

  const regex = new RegExp(searchQuery, "i");

  const orderedMenus = valuesOrdered(filteredMenus);

  let $menus = orderedMenus
    .map(menu => {
      let menuMatch = false;

      if (menu.name.match(regex)) {
        menuMatch = true;
      }

      const menuCats = menu.children.map(catId => {
        const category = categories[catId];
        let categoryMatch = false;

        if (category.name.match(regex)) {
          menuMatch = true;
          categoryMatch = true;
        }

        const catItems = category.children
          .map(itemId => {
            const item = items[itemId];

            if (
              !item.name.match(regex) &&
              !item.description.match(regex) &&
              !categoryMatch &&
              !menuMatch
            ) {
              return "";
            }

            const itemOptions = item.children.map(optId => {
              const option = options[optId];

              return (
                <div className="item-option" key={option.id}>
                  <div className="item-option-name">{option.name}</div>
                  <div className="item-option-price">${option.price}</div>
                </div>
              );
            });

            return (
              <div className="item border-b border-x-lr" key={item.id}>
                <div className="item-title">{item.name}</div>
                <p className="item-description">{item.description}</p>
                <div className="item-options">{itemOptions}</div>
              </div>
            );
          })
          .filter(el => el !== "");

        if (catItems.length) {
          menuMatch = true;
          categoryMatch = true;
        }

        if (!menuMatch && !categoryMatch) return "";

        return (
          <React.Fragment key={category.id}>
            <div
              className="category-title border-b border-x-lr"
              id={`menu_category_${category.id}`}
            >
              <h4>{category.name}</h4>
              {editing && (
                <CustomButton
                  className="btn-text"
                  onClick={() => openItemsModal(category.id)}
                >
                  Edit Items
                </CustomButton>
              )}
            </div>
            <div className="category-items">{catItems}</div>
          </React.Fragment>
        );
      });

      if (!menuMatch) return "";

      return (
        <div className="menu" key={menu.id}>
          <div className="menu-title border-b border-x-lr">
            <h3>{menu.name}</h3>
            {editing && (
              <CustomButton
                className="btn-text"
                onClick={() => openCategoriesModal(menu.id)}
              >
                Edit Categories
              </CustomButton>
            )}
          </div>

          <div className="categories">{menuCats}</div>
        </div>
      );
    })
    .filter(el => el !== "");

  return (
    <div className="restaurant-menu">
      {!$menus.length ? (
        <div className="no-results">
          <img src="/static_assets/img/NoResults_V01_once.gif" />
        </div>
      ) : (
        $menus
      )}

      <EditCategoriesModal />
      <EditItemsModal />
    </div>
  );
};

const mapStateToProps = state => ({
  menus: state.menu.menus,
  categories: state.menu.categories,
  items: state.menu.items,
  options: state.menu.options
});

export default connect(
  mapStateToProps,
  { setLayoutModal }
)(RestaurantMenu);

/*
    let $menus = menus.map((menu, idMenu) => {
      // Check if the menu matches or if any category matches
      let menuMatches = false;
      let categoriesMatches = false;
      if (menu.name.match(regex)) {
        menuMatches = true;
      }

      const categories = menu["menu_categories"].map((category, idCategory) => {
        if (!category["menu_items"].length) return "";

        // Check if the category or if any item matches
        let categoryMatches = false;
        let itemsMatches = false;
        if (category.name.match(regex)) {
          categoryMatches = true;
        }

        const items = category["menu_items"].map((item, idItem) => {
          let itemMatches = false;
          let optionsMatches = false;
          if (item.name.match(regex) || item.description.match(regex)) {
            itemMatches = true;
          }

          const options = item["menu_item_options"].map((option, idOption) => {
            if (option.name.match(regex) || option.price.match(regex)) {
              optionsMatches = true;
            }

            return (
              <div className="item-option" key={option.id}>
                <div className="item-option-name">{option.name}</div>
                <div className="item-option-price">${option.price}</div>
              </div>
            );
          });

          if (itemMatches || optionsMatches || categoryMatches) {
            itemsMatches = true;
          } else {
            return "";
          }

          return (
            <div className="item border-b border-x-lr" key={item.id}>
              <div className="item-title">{item.name}</div>
              <p className="item-description">{item.description}</p>
              <div className="item-options">{options}</div>
            </div>
          );
        });

        // If any matches then the categories matches else return nothing
        if (categoryMatches || itemsMatches || menuMatches) {
          categoriesMatches = true;
        } else {
          return "";
        }

        return (
          <React.Fragment key={category.id}>
            <h4
              className="category-title border-b border-x-lr"
              id={`menu_category_${category.id}`}
            >
              {category.name}
            </h4>
            <div className="category-items">{items}</div>
          </React.Fragment>
        );
      });

      // If that's not the case then return nothing
      if (!menuMatches && !categoriesMatches) return "";

      return (
        <div className="menu" key={menu.id}>
          <h3 className="menu-title border-b border-x-lr">{menu.name}</h3>
          <div className="categories">{categories}</div>
        </div>
      );
    });*/

//$menus = $menus.filter(el => el !== "");
