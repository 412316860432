// Errors
export const SET_ERRORS = "SET_ERRORS";
export const RESET_ERRORS = "RESET_ERRORS";

// Auth
export const SET_AUTH_USER = "SET_AUTH_USER";
export const SET_AUTH_PROFILE = "SET_AUTH_PROFILE";
export const SET_FAVORITE_ITEM = "SET_FAVORITE_ITEM";

// Users
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const USERS_SET_USERS = "USERS_SET_USERS";

// Profile
export const SET_PROFILE = "SET_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const CLEAR_CURRENT_PROFILE = "CLEAR_CURRENT_PROFILE";

// Layout
export const ADD_MSG = "ADD_MSG";
export const RESET_MSG = "RESET_MSG";
export const SET_AUTH_MODAL = "SET_AUTH_MODAL";
export const SET_LOADING = "SET_LOADING";
export const RESET_LOADING = "RESET_LOADING";
export const LAYOUT_SET_MODAL_TYPE = "LAYOUT_SET_MODAL_TYPE";

// Restaurants
export const GET_RESTAURANTS = "GET_RESTAURANTS";
export const GET_RESTAURANT = "GET_RESTAURANT";
export const SET_RESTAURANT_UPDATE_TYPE = "SET_RESTAURANT_UPDATE_TYPE";
export const SET_RESTAURANT_SEARCH_RESULT = "SET_RESTAURANT_SEARCH_RESULT";
export const UPDATE_RESTAURANT_MENUS = "UPDATE_RESTAURANT_MENUS";
export const SET_RESTAURANT_MENUS = "SET_RESTAURANT_MENUS";
export const RESTAURANTS_SET_SELECTED = "RESTAURANTS_SET_SELECTED";
export const RESTAURANTS_SET_RESTAURANTFORM = "RESTAURANTS_SET_RESTAURANTFORM";
export const RESTAURANTS_UPDATE_RESTAURANT = "RESTAURANTS_UPDATE_RESTAURANT";

// Cuisines
export const SET_CUISINES = "SET_CUISINES";
export const ADD_RESTAURANTS_TO_CUISINES = "ADD_RESTAURANTS_TO_CUISINES";
export const UPDATE_CUISINES = "UPDATE_CUISINES";

// Menus
export const MENUS_SET_ALL = "MENUS_SET_ALL";
export const MENUS_SET_MENUS = "MENUS_SET_MENUS";
export const MENUS_SET_CATEGORIES = "MENUS_SET_CATEGORIES";
export const MENUS_SET_ITEMS = "MENUS_SET_ITEMS";
export const MENUS_SET_OPTIONS = "MENUS_SET_OPTIONS";
