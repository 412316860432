import React, { useState, useEffect } from "react";

import { connect, useDispatch } from "react-redux";
import { setLayoutModal } from "../../../actions/layoutActions";

import { Modal, Badge, Input, Icon } from "antd";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import CustomButton from "../../common/CustomButton";
import { reorder, orderArrayFromPosition, reverse } from "../../../utils/array";
import ClassNames from "classnames";

import "./EditCategoriesModal.less";

const getItemStyle = (isDragging, draggableStyle) => ({
  borderTop: isDragging ? "solid 1px #eaeaea" : "none",
  ...draggableStyle
});

const EditCategoriesModal = ({
  modalType,
  menus,
  categories,
  setLayoutModal,
  ...rest
}) => {
  const [list, setList] = useState([]);
  const [editing, setEditing] = useState(null);
  const currentModal = modalType["MODAL_MENU_CATEGORIES"];
  const visible = !!currentModal;
  const dispatch = useDispatch();

  const closeModal = () => {
    setLayoutModal({
      type: "MODAL_MENU_CATEGORIES",
      id: currentModal.id,
      delete: true
    });
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // Update menus children
    let newMenu = { ...menus[currentModal.id] };

    const newChildrenOrder = reorder(
      newMenu.children,
      result.source.index,
      result.destination.index
    );

    newMenu.children = newChildrenOrder;

    dispatch({
      type: "MENUS_SET_MENUS",
      payload: [newMenu]
    });

    // Update categories
    const newCategories = reorder(
      list,
      result.source.index,
      result.destination.index
    );

    dispatch({
      type: "MENUS_SET_CATEGORIES",
      payload: newCategories
    });

    setList(newCategories);
  };

  const updateCategoriesList = newCategories => {
    newCategories = orderArrayFromPosition(newCategories);
    const newMenu = { ...menus[currentModal.id] };
    newMenu.children = newCategories.map(el => el.id);

    dispatch({
      type: "MENUS_SET_MENUS",
      payload: [newMenu]
    });

    dispatch({
      type: "MENUS_SET_CATEGORIES",
      payload: newCategories
    });

    setList(newCategories);
  };

  const onChange = e => {
    setList(
      list.map(el => {
        if (el.id === editing) el.name = e.target.value;
        return el;
      })
    );
  };

  const onSave = () => {
    const catEditing = list.findIndex(el => el.id === editing);

    if (catEditing === -1) return;

    dispatch({
      type: "MENUS_SET_CATEGORIES",
      payload: [list[catEditing]]
    });

    setEditing(null);
  };

  useEffect(() => {
    if (currentModal && menus[currentModal.id])
      setList(menus[currentModal.id].children.map(id => categories[id]));
  }, [categories, currentModal]);

  const title = (
    <div className="dashboard-modal-form-title">
      <h2>Edit Categories</h2>
      <span>Add new categories to this menu</span>
    </div>
  );

  const footer = (
    <div className="dashboard-modal-form-footer">
      <CustomButton
        key="back"
        type="secondary"
        className="btn-text"
        onClick={closeModal}
      >
        Close
      </CustomButton>
    </div>
  );

  return (
    <Modal
      visible={visible}
      onOk={closeModal}
      onCancel={closeModal}
      footer={footer}
      title={title}
      centered={true}
      wrapClassName={ClassNames("edit-modal", {
        "edit-modal-1":
          modalType["MODAL_MENU_CATEGORIES"] && modalType["MODAL_MENU_ITEMS"]
      })}
      mask={
        modalType["MODAL_MENU_CATEGORIES"] && !modalType["MODAL_MENU_ITEMS"]
      }
      bodyStyle={{
        padding: 0
      }}
    >
      {visible && (
        <div className="edit-modal-container">
          <div className="edit-modal-row-header border-b">
            <h3>{currentModal && menus[currentModal.id].name}</h3>
            <CustomButton
              type="primary"
              className="btn-text"
              style={{ marginLeft: "auto", marginRight: 8 }}
              onClick={() => {
                updateCategoriesList(reverse(list));
              }}
            >
              Reverse
            </CustomButton>
            <CustomButton
              type="primary"
              className="btn-text"
              onClick={() => {
                let id = "new" + Math.random();

                setList([
                  ...list,
                  {
                    id,
                    menu_id: currentModal.id,
                    name: "",
                    children: []
                  }
                ]);
                setEditing(id);
              }}
            >
              Add New
            </CustomButton>
          </div>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {orderArrayFromPosition(list).map((cat, index) => (
                    <Draggable key={cat.id} draggableId={cat.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          className="edit-modal-item border-b"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {editing === cat.id ? (
                            <Input
                              type="text"
                              onChange={onChange}
                              value={cat.name}
                            />
                          ) : (
                            <span>{cat.name}</span>
                          )}

                          <Badge
                            count={cat.children.length}
                            style={{
                              backgroundColor: "#fff",
                              color: "#999",
                              boxShadow: "0 0 0 1px #d9d9d9 inset"
                            }}
                          />

                          {editing === cat.id ? (
                            <CustomButton
                              type="primary"
                              className="btn-text"
                              onClick={onSave}
                            >
                              Save
                            </CustomButton>
                          ) : (
                            <CustomButton
                              type="primary"
                              className="btn-text"
                              onClick={() => setEditing(cat.id)}
                            >
                              Edit
                            </CustomButton>
                          )}

                          <CustomButton
                            type="primary"
                            className="btn-text"
                            onClick={() => {
                              setLayoutModal({
                                type: "MODAL_MENU_ITEMS",
                                id: cat.id
                              });
                            }}
                            disabled={!categories[cat.id]}
                          >
                            Items
                          </CustomButton>

                          <CustomButton
                            type="tertiary"
                            className="btn-icon"
                            onClick={() => {
                              setList(list.filter(el => el.id !== cat.id));

                              let newCategories = list.map(el => {
                                if (el.id === cat.id) el.delete = true;
                                return el;
                              });

                              dispatch({
                                type: "MENUS_SET_CATEGORIES",
                                payload: newCategories
                              });
                            }}
                          >
                            <Icon type="close" />
                          </CustomButton>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = state => ({
  modalType: state.layout.modalType,
  menus: state.menu.menus,
  categories: state.menu.categories
});

export default connect(
  mapStateToProps,
  { setLayoutModal }
)(EditCategoriesModal);
