import React, { Component, useEffect, useState } from "react";

import { Menu, Input, Icon, Checkbox } from "antd";

import { connect } from "react-redux";
import { getAllUsers } from "../../../../actions/usersActions";

import CustomButton from "../../../common/CustomButton";
import IconCustom from "../../../common/IconCustom";

import "./People.less";

import { filterUsers } from "../../../../utils/array";

const usersEx = [
  {
    name: "Matt Myers",
    username: "mattmyers",
    email: "matt@engn.com",
    role: "Master Admin",
    phone: "",
    restaurants: []
  },
  {
    name: "Jen",
    username: "jen1234",
    email: "jen@queensu.com",
    role: "Admin",
    phone: "",
    restaurants: []
  },
  {
    name: "David",
    username: "davidkelseys",
    email: "david@kelseys.com",
    role: "",
    phone: "",
    restaurants: [
      {
        name: "Balzac's Coffe Roasters",
        role: "Owner",
        phone: "613-111-1111",
        branches: [
          {
            delivery: "Yes",
            featured: "10$",
            favs: 1234,
            location: "125 Princess Street, Kingston, ON"
          }
        ]
      },
      {
        name: "Kelsey's",
        role: "Owner",
        phone: "613-111-1111",
        branches: [
          {
            delivery: "Yes",
            featured: 0,
            favs: 0,
            location: "1187 Princess Street, Kingston, ON"
          },
          {
            delivery: "Yes",
            featured: 0,
            favs: 5,
            location: "650 Gardiners Road, Kingston, ON"
          }
        ]
      }
    ]
  },
  {
    name: "Elon Musk",
    username: "goatboi",
    email: "elon@x.com",
    role: "",
    phone: "",
    restaurants: []
  }
];

const People = ({ users, getAllUsers, ...rest }) => {
  const [current, setCurrent] = useState("all");

  useEffect(() => {
    getAllUsers();
  }, []);

  console.log(users);

  return (
    <div className="content border-r">
      <div className="menu border-b">
        <Menu
          onClick={e => setCurrent(e.key)}
          selectedKeys={[current]}
          mode="horizontal"
        >
          <Menu.Item key="all">
            <span>All People</span>
          </Menu.Item>
          <Menu.Item key="users">
            <span>Users</span>
          </Menu.Item>
          <Menu.Item key="owners_managers">
            <span>Owners & Managers</span>
          </Menu.Item>
          <Menu.Item key="admins">
            <span>Admins</span>
          </Menu.Item>
        </Menu>

        <span></span>
        {/*<Checkbox>Show Restaurant(s)</Checkbox> */}

        <span className="number-restaurants" style={{ marginRight: 20 }}>
          {filterUsers(users, current).length} People
        </span>

        {/*
                <CustomButton
          type="primary"
          style={{ fontSize: 14 }}
          className="btn-text"
        >
          Create Account
        </CustomButton>
        */}
      </div>

      <div className="dash-header border-b dash-header-people">
        <span>Name</span>
        <span>Username</span>
        <span>Email</span>
        <span>Role</span>
        <span>Phone</span>
        <div className="inputs">
          <Input type="text" placeholder="Search" />
        </div>
      </div>

      <div className="list user-list">
        {filterUsers(users, current).map((user, index) => {
          return (
            <>
              <div className="user border-b">
                <span>{user.name}</span>
                <span>{user.username}</span>
                <span>{user.email}</span>
                {user.roles.length ? (
                  <span>{user.roles[0].name}</span>
                ) : (
                  <span></span>
                )}
                <span>{user.phone}</span>
                {/*
                                <div className="buttons">
                  <CustomButton type="secondary" className="btn-text">
                    Edit
                  </CustomButton>
                </div>
                */}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  users: state.user.users
});

export default connect(
  mapStateToProps,
  { getAllUsers }
)(People);
