import React, { useState, useEffect } from "react";

import "./RestaurantSchedules.less";

const initialStateScheduleList = {
  monday: {
    day: "Monday",
    hours: []
  },
  tuesday: {
    day: "Tuesday",
    hours: []
  },
  wednesday: {
    day: "Wednesday",
    hours: []
  },
  thursday: {
    day: "Thursday",
    hours: []
  },
  friday: {
    day: "Friday",
    hours: []
  },
  saturday: {
    day: "Saturday",
    hours: []
  },
  sunday: {
    day: "Sunday",
    hours: []
  }
};

const weekdays = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday"
];

const RestaurantSchedules = ({ schedules, ...props }) => {
  const [scheduleList, setScheduleList] = useState({
    monday: {
      day: "Monday",
      hours: []
    },
    tuesday: {
      day: "Tuesday",
      hours: []
    },
    wednesday: {
      day: "Wednesday",
      hours: []
    },
    thursday: {
      day: "Thursday",
      hours: []
    },
    friday: {
      day: "Friday",
      hours: []
    },
    saturday: {
      day: "Saturday",
      hours: []
    },
    sunday: {
      day: "Sunday",
      hours: []
    }
  });

  useEffect(() => {
    let list = {
      monday: {
        day: "Monday",
        hours: []
      },
      tuesday: {
        day: "Tuesday",
        hours: []
      },
      wednesday: {
        day: "Wednesday",
        hours: []
      },
      thursday: {
        day: "Thursday",
        hours: []
      },
      friday: {
        day: "Friday",
        hours: []
      },
      saturday: {
        day: "Saturday",
        hours: []
      },
      sunday: {
        day: "Sunday",
        hours: []
      }
    };

    for (let schedule of schedules) {
      let from = schedule.from.match(/.+?(?=:00)/);
      if (!from.length) continue;
      from = from[0];
      from =
        parseInt(from.substring(0, 2)) > 12
          ? parseInt(from.substring(0, 2)) - 12 + from.substring(2) + "PM"
          : from + "AM";

      let to = schedule.to.match(/.+?(?=:00)/);
      if (!to.length) continue;
      to = to[0];
      to =
        parseInt(to.substring(0, 2)) > 12
          ? parseInt(to.substring(0, 2)) - 12 + to.substring(2) + "PM"
          : to + "AM";

      list[weekdays[schedule.weekday]].hours.push({
        from,
        to
      });
    }

    // Now sort them for date
    for (let day of Object.values(list)) {
      day.hours.sort(
        (a, b) => parseInt(a.from.substring(0, 2)) > parseInt(b.from.substring(0, 2))
      );
    }

    setScheduleList(list);
  }, [schedules]);

  return (
    <div className="schedules-popover">
      {Object.values(scheduleList).map(schedule => {
        return (
          <React.Fragment key={schedule.day}>
            <div className="schedule-day">{schedule.day}</div>
            <div className="schedule-times">
              {schedule.hours.length ? (
                schedule.hours.map((hour, id) => {
                  return (
                    <div className="time" key={id}>
                      {hour.from} - {hour.to}
                    </div>
                  );
                })
              ) : (
                <div className="time">Closed</div>
              )}
            </div>
          </React.Fragment>
        );
      })}
      <div className="schedule-msg">
        Holidays may affect these times,
        <br />
        contact location to confirm.
      </div>
    </div>
  );
};

export default RestaurantSchedules;
