import React from "react";

import classNames from "classnames";

import { Icon } from "antd";

import "./IconCustom.less";

const IconCustom = ({ icon, className, ...props }) => {
  return (
    <Icon
      {...props}
      className={classNames(className, "icon-custom")}
      component={require(`../../assets/icons/${icon}.svg`).default}
    />
  );
};

export default IconCustom;
