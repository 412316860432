import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getRestaurant,
  setRestaurantUpdateType,
  setRestaurantMenus,
} from "../../../actions/restaurantActions";
import { updateFavorites } from "../../../actions/authActions";
import { setLayoutModal } from "../../../actions/layoutActions";
import { updateMenus, getRestaurantMenu } from "../../../actions/menusActions";
import PropTypes from "prop-types";
import { Popover, Input, Menu, Select } from "antd";
import classNames from "classnames";
import cloneDeep from "lodash.clonedeep";
import { valuesOrdered, reverseAll } from "../../../utils/array";

import DocumentMeta from "react-document-meta";

import ReactGA from "react-ga";

import Lottie from "react-lottie";
import animationLogoURI from "../../../assets/lottie/Logo_MenuSite_V02.json";

import RestaurantEditableMenu from "./RestaurantEditableMenu";

import CustomButton from "../../common/CustomButton";
import IconCustom from "../../common/IconCustom";
import OpenTag from "../../common/OpenTag";
import RestaurantMenu from "./RestaurantMenu";
import RestaurantMap from "./RestaurantMap";

import EditMenusModal from "./EditMenusModal";

import "./Restaurant.less";

class Restaurant extends Component {
  state = {
    tab: "all",
    restaurant: {},
    backupRestaurant: {},
    menuSearchQuery: "",
    mapOpen: false,
    favorite: false,
    phoneOpen: false,
    editing: false,
    authenticated: false,
    animationLogo: false,
    animationLoading: false,
    animationLoadingText: false,
  };

  componentDidMount() {
    ReactGA.pageview(this.props.location.pathname);

    this.props.getRestaurant({
      restaurantSlug: this.props.match.params.slug,
    });
    this.props.getRestaurantMenu({
      restaurantSlug: this.props.match.params.slug,
    });

    if (this.props.location.search) {
      let mapOpen = this.props.location.search.match(/mapOpen=([^&]*)/);
      if (mapOpen) {
        this.setState({
          mapOpen: mapOpen[1] === "true",
        });
      }
    }

    if (this.props.location.state && this.props.location.state.prevPath) {
      let $showcase = document.querySelector(".showcase");

      window.scrollTo(0, $showcase.clientHeight);
    }

    fetch(animationLogoURI)
      .then((a) => a.json())
      .then((json) => this.setState({ animationLogo: json }));

    fetch("/static_assets/json/Hat_Loader_V05_NoText.json")
      .then((a) => a.json())
      .then((json) => this.setState({ animationLoading: json }));

    fetch("/static_assets/json/MENULOADING_02.json")
      .then((a) => a.json())
      .then((json) => this.setState({ animationLoadingText: json }));

    this.setState({
      authenticated: this.props.isAuthenticated,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      (!Object.keys(prevProps.restaurant).length &&
        Object.keys(this.props.restaurant).length) ||
      prevProps.restaurant.id !== this.props.restaurant.id
    ) {
      this.setState({
        restaurant: this.props.restaurant,
        backupRestaurant: cloneDeep(this.props.restaurant),
      });
    }

    if (this.props.restaurant !== this.state.restaurant) {
      const favorite = !!Object.values(this.props.favorites).filter(
        (cuisine) =>
          Object.values(cuisine.restaurants).filter(
            (restaurant) =>
              restaurant.id === this.props.restaurant.id && restaurant.active
          ).length
      ).length;

      this.setState({
        restaurant: this.props.restaurant,
        favorite,
      });

      if (this.props.isAuthenticated && this.props.restaurant.editable) {
        let editing = this.props.location.search.match(/editing=([^&]*)/);
        if (editing) {
          this.setState({
            editing: editing[1] === "true",
          });
        }
      }
    }

    if (this.props.isAuthenticated !== prevProps.isAuthenticated) {
      this.setState({
        authenticated: this.props.isAuthenticated,
      });
      this.props.getRestaurant({
        restaurantSlug: this.props.match.params.slug,
      });
    }

    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({
        restaurant: {},
      });

      ReactGA.pageview(this.props.location.pathname);

      this.props.getRestaurant({
        restaurantSlug: this.props.match.params.slug,
      });
      this.props.getRestaurantMenu({
        restaurantSlug: this.props.match.params.slug,
      });

      if (this.props.location.search) {
        let mapOpen = this.props.location.search.match(/mapOpen=([^&]*)/);
        if (mapOpen) {
          this.setState({
            mapOpen: mapOpen[1] === "true",
          });
        }
      }

      if (this.props.location.state && this.props.location.state.prevPath) {
        let $showcase = document.querySelector(".showcase");

        window.scrollTo(0, $showcase.clientHeight);
      }
    }

    if (
      prevProps.loadingType === "uploadingMenus" &&
      this.props.loadingType === ""
    ) {
      this.props.getRestaurantMenu({
        restaurantSlug: this.props.restaurant.slug,
      });

      this.setState({
        editing: false,
      });
    }
  }

  onClickFavorites = (active) => (e) => {
    e.stopPropagation();

    const favoriteData = {
      favorite_type: "Restaurant",
      favorite_type_id: this.props.restaurant.id,
      active: !active,
    };

    this.props.updateFavorites(this.props.username, favoriteData);

    this.setState({
      favorite: !active,
    });
  };

  handleClick = (e) => {
    this.setState({ tab: e.key });
  };

  openMap = (e) => {
    this.setState({ mapOpen: !this.state.mapOpen });
  };

  scrollToCategory = (id) => {
    /*
    document.querySelector(`#menu_category_${id}`).scrollIntoView({
      behavior: "smooth",
      block: "start"
    });*/

    window.scroll({
      top: document.querySelector(`#menu_category_${id}`).offsetTop - 60,
      behavior: "smooth",
    });
  };

  onChangeSearch = (e) => {
    this.setState({ menuSearchQuery: e.target.value });
  };

  render() {
    const {
      restaurant,
      tab,
      menuSearchQuery,
      mapOpen,
      favorite,
      editing,
      phoneOpen,
      authenticated,
      animationLogo,
      animationLoading,
      animationLoadingText,
    } = this.state;
    const {
      menus,
      categories,
      items,
      options,
      loading,
      loadingType,
      dispatch,
    } = this.props;

    const orderedMenus = valuesOrdered(menus);

    let restaurantName = restaurant.name ? restaurant.name : "Loading...";

    document.title = `${restaurantName} Kingston - MENUSITE :: All Restaurants. All Menus.`;

    const meta = {
      title: `${restaurantName} Kingston - MENUSITE :: All Restaurants. All Menus.`,
      meta: {
        charset: "utf-8",
        description: `${restaurantName} Menu and Open Hours`,
        keywords: `${restaurantName} kingston, ${restaurantName} menu, ${restaurantName} restaurant, ${restaurantName} open hours`,
        copyright: "MenuSite",
      },
    };

    const showcase = (
      <div className="showcase border-x border-b">
        <div>
          <h1>
            MENU<span className="text-blue">SITE</span>
          </h1>
          <h2>All Restaurants. All Menus.</h2>
        </div>

        <div className="logo-full">
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: animationLogo,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
          />
        </div>

        <CustomButton
          type="secondary"
          onClick={() => this.props.history.push("/")}
        >
          View All Kingston Menus
        </CustomButton>

        {restaurant && restaurant.editable && (
          <CustomButton
            type="primary"
            className="btn-edit"
            onClick={() => {
              if (this.state.editing) {
              }

              this.setState({ editing: !editing });
            }}
          >
            {editing ? "Exit Editing" : "Edit Menu"}
          </CustomButton>
        )}
      </div>
    );

    if (!Object.keys(restaurant).length) {
      return (
        <div style={{ minHeight: "100vh" }}>
          <main className="container">
            <div className="content" id="restaurant">
              {showcase}
              <div
                className="border-x"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: 50,
                }}
              >
                <div style={{ width: 250 }}>
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: animationLoading,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                  />
                </div>
                <div style={{ width: 150, paddingBottom: 150 }}>
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: animationLoadingText,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
      );
    } else {
      return (
        <DocumentMeta meta={meta}>
          <main className="container">
            <div
              className="content"
              id="restaurant"
              style={{ minHeight: "100vh" }}
            >
              {showcase}

              <div
                className={classNames(
                  "border-b map-wrapper",
                  mapOpen ? "open" : ""
                )}
              >
                <RestaurantMap
                  location={restaurant["restaurant_branches"][0].location}
                />
              </div>

              <div className="restaurant border-x border-b">
                <div className="restaurant-info">
                  <span className="restaurant-title">{restaurant.name}</span>
                  <small className="restaurant-dir">
                    {restaurant["restaurant_branches"][0].location.address}
                  </small>
                </div>
                <div className="btn-list">
                  {authenticated && favorite ? (
                    <CustomButton
                      className="btn-fav"
                      type="tertiary"
                      onClick={this.onClickFavorites(true)}
                    >
                      <IconCustom icon="noun-hearth" />
                    </CustomButton>
                  ) : (
                    <CustomButton
                      className="btn-fav"
                      type="tertiary"
                      onClick={this.onClickFavorites(false)}
                    >
                      <IconCustom icon="noun-hearth-outline" />
                    </CustomButton>
                  )}

                  <CustomButton
                    className="btn-call"
                    type={phoneOpen ? "primary" : "tertiary"}
                    onClick={() => this.setState({ phoneOpen: !phoneOpen })}
                  >
                    <IconCustom icon="noun-phone" />
                  </CustomButton>

                  <a href={`tel:${restaurant["restaurant_branches"][0].phone}`}>
                    <div
                      className={classNames("phone-number-content", {
                        show: phoneOpen,
                      })}
                    >
                      {restaurant["restaurant_branches"][0].phone}
                    </div>
                  </a>

                  <CustomButton
                    type={mapOpen ? "primary" : "tertiary"}
                    className="btn-text btn-map"
                    onClick={this.openMap}
                    style={{ width: 71 }}
                  >
                    Map
                  </CustomButton>

                  {/*
                  <CustomButton type="tertiary" className="btn-text btn-more-info">
                    More Info
                  </CustomButton>
                  */}
                </div>
                <OpenTag
                  openSchedule={restaurant.open_schedule}
                  schedules={restaurant.restaurant_branches[0].schedules}
                />
              </div>

              {/*
                                <RestaurantEditableMenu
                  tabSelected={this.state.tab}
                  handleMenuClick={this.handleClick}
                  restaurant={restaurant}
                />
                */}

              {loadingType === "gettingMenus" ? (
                <div
                  className="border-x"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: 50,
                  }}
                >
                  <div style={{ width: 250 }}>
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: animationLoading,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        },
                      }}
                    />
                  </div>
                  <div style={{ width: 150, paddingBottom: 150 }}>
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: animationLoadingText,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        },
                      }}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div className="border-x nav-menu-container">
                    <Menu
                      selectedKeys={[this.state.tab]}
                      onClick={this.handleClick}
                      mode="horizontal"
                      className="nav-menu "
                    >
                      <Menu.Item key="all">All Menus</Menu.Item>
                      {orderedMenus.map((menu) => (
                        <Menu.Item key={menu.id}>{menu.name}</Menu.Item>
                      ))}
                    </Menu>

                    {editing && (
                      <CustomButton
                        type="primary"
                        className="btn-text"
                        onClick={() =>
                          this.props.setLayoutModal({
                            type: "MODAL_MENUS",
                          })
                        }
                      >
                        Edit menus
                      </CustomButton>
                    )}
                  </div>

                  <div
                    className="wrapper border-x border-b   "
                    style={{ paddingBottom: "200px" }}
                  >
                    <div className="mobile-index">
                      <div className="mobile-index-menu border-b border-x">
                        <Menu selectedKeys={["menus"]} mode="horizontal">
                          <Menu.Item key="menus">Menus</Menu.Item>
                        </Menu>

                        <Input
                          className="search-bar-input"
                          placeholder="Search Menus"
                          prefix={<IconCustom icon="noun-search" />}
                          value={menuSearchQuery}
                          onChange={this.onChangeSearch}
                        />
                      </div>
                    </div>

                    <RestaurantMenu
                      menus={menus}
                      selectedMenu={this.state.tab}
                      editing={editing}
                      searchQuery={menuSearchQuery}
                    />

                    <div className="index">
                      <div className="search-bar">
                        <Input
                          className="search-bar-input"
                          placeholder="Search Menus"
                          prefix={<IconCustom icon="noun-search" />}
                          value={menuSearchQuery}
                          onChange={this.onChangeSearch}
                        />
                      </div>
                      <div className="index-menus">
                        {orderedMenus.map((menu) => {
                          let atLeastOneCategory = false;

                          const menuCats = menu.children.map((categoryId) => {
                            const category = categories[categoryId];

                            if (!category.children.length) return "";

                            atLeastOneCategory = true;

                            return (
                              <li
                                key={category.id}
                                className="border-b"
                                onClick={() =>
                                  this.scrollToCategory(category.id)
                                }
                              >
                                {category.name}
                              </li>
                            );
                          });

                          if (!atLeastOneCategory) return "";

                          return (
                            <div className="index-menu" key={menu.id}>
                              <div className="index-menu-title border-b">
                                <p>{menu.name}</p>
                              </div>

                              <ul className="index-menu-categories">
                                {menuCats}
                              </ul>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  {editing && (
                    <div id="editing-menu">
                      <ul>
                        <li>
                          <CustomButton
                            type="primary"
                            className="btn-text btn-edit-cancel"
                            onClick={() => {
                              const reversed = reverseAll({
                                menus: Object.values(menus),
                                categories: Object.values(categories),
                                items: Object.values(items),
                              });

                              dispatch({
                                type: "MENUS_SET_MENUS",
                                payload: reversed.menus,
                              });

                              dispatch({
                                type: "MENUS_SET_CATEGORIES",
                                payload: reversed.categories,
                              });

                              dispatch({
                                type: "MENUS_SET_ITEMS",
                                payload: reversed.items,
                              });
                            }}
                          >
                            Reverse All
                          </CustomButton>
                        </li>
                        <li>
                          <CustomButton
                            type="primary"
                            className="btn-text btn-edit-cancel"
                            onClick={() => {
                              this.props.getRestaurantMenu({
                                restaurantSlug: restaurant.slug,
                              });

                              this.setState({
                                editing: false,
                              });
                            }}
                          >
                            Cancel
                          </CustomButton>
                        </li>
                        <li>
                          <CustomButton
                            type="primary"
                            className="btn-text btn-edit-save"
                            loading={loading}
                            onClick={() => {
                              this.props.updateMenus({
                                restaurantSlug: restaurant.slug,
                                menus,
                                categories,
                                items,
                                options,
                              });
                            }}
                          >
                            Save
                          </CustomButton>
                        </li>
                      </ul>
                    </div>
                  )}
                </>
              )}
            </div>
          </main>

          <EditMenusModal />
        </DocumentMeta>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  restaurant: state.restaurant.restaurant,
  restaurantUpdated: state.restaurant.restaurantUpdated,
  favorites: state.auth.favorites,
  username: state.auth.user.username,
  menus: state.menu.menus,
  categories: state.menu.categories,
  items: state.menu.items,
  options: state.menu.options,
  loading: state.layout.loading,
  loadingType: state.layout.loadingType,
});

export default connect(mapStateToProps, {
  getRestaurant,
  setRestaurantUpdateType,
  updateFavorites,
  setRestaurantMenus,
  updateRestaurantMenus: (newRestaurant) => (dispatch) =>
    dispatch({
      type: "UPDATE_RESTAURANT_MENUS",
      payload: newRestaurant,
    }),
  setLayoutModal,
  updateMenus,
  getRestaurantMenu,
  dispatch: (data) => (dispatch) => dispatch(data),
})(Restaurant);
