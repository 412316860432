import React, { Component } from "react";
import { Menu, Input, Icon } from "antd";

import { connect } from "react-redux";
import { getUserRestaurants } from "../../../actions/restaurantActions";

import IconCustom from "../../common/IconCustom";

import Main from "./components/Main";
import People from "./components/People";
import Areas from "./components/Areas";

import "./Dashboard.less";

class Dashboard extends Component {
  state = {
    tab: "dashboard"
  };

  componentDidMount() {
    this.props.getUserRestaurants({ username: this.props.username });
  }

  handleClick = e => {
    this.setState({ tab: e.key });
  };

  render() {
    const { tab } = this.state;

    return (
      <div className="dashboard">
        <main className="container">
          <Menu
            className="aside-menu border-l border-b"
            style={{ width: 195 }}
            selectedKeys={[this.state.tab]}
            onClick={this.handleClick}
          >
            <Menu.Item key="dashboard">
              <IconCustom icon="view-grid" />
              Dashboard
            </Menu.Item>
            <Menu.Item key="people">
              <Icon type="team" className="icon-custom" />
              People
            </Menu.Item>
            {/*
            <Menu.Item key="areas">
              <Icon type="environment" className="icon-custom" />
              Areas
            </Menu.Item>
            */}
          </Menu>

          {tab === "dashboard" ? (
            <Main />
          ) : tab === "people" ? (
            <People />
          ) : (
            <Areas />
          )}
        </main>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  username: state.auth.user.username
});

export default connect(
  mapStateToProps,
  { getUserRestaurants }
)(Dashboard);
