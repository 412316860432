import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { updateSchedules } from "../../../../actions/restaurantActions";

import moment from "moment";

import {
  Modal,
  Input,
  Form,
  Icon,
  Select,
  Checkbox,
  Tag,
  message,
  TimePicker
} from "antd";

import CustomButton from "../../../common/CustomButton";

import "./ModalOpenHours.less";

const openHoursWeekdays = {
  monday: {
    id: "monday",
    day: "Monday",
    hours: []
  },
  tuesday: {
    id: "tuesday",
    day: "Tuesday",
    hours: []
  },
  wednesday: {
    id: "wednesday",
    day: "Wednesday",
    hours: []
  },
  thursday: {
    id: "thursday",
    day: "Thursday",
    hours: []
  },
  friday: {
    id: "friday",
    day: "Friday",
    hours: []
  },
  saturday: {
    id: "saturday",
    day: "Saturday",
    hours: []
  },
  sunday: {
    id: "sunday",
    day: "Sunday",
    hours: []
  }
};

const weekdaysIds = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday"
];

const ModalOpenHours = ({ showModal, visible, restaurant, ...props }) => {
  const [newHour, setNewHour] = useState({
    weekday: null,
    from: null,
    to: null
  });
  const [openHours, setOpenHours] = useState([]);
  const [openHoursDom, setOpenHoursDom] = useState({});
  const dispatch = useDispatch();
  const loading = useSelector(state => state.layout.loading);

  useEffect(() => {
    if (restaurant.restaurant_branches)
      setOpenHours(restaurant.restaurant_branches[0].schedules);
  }, [restaurant]);

  useEffect(() => {
    const weekdays = Object.assign({}, openHoursWeekdays);

    for (let day of Object.values(weekdays)) {
      day.hours = [];
    }

    for (let hour of openHours) {
      let hourDom = Object.assign({}, hour);

      let time = hourDom.from.split(":");
      hourDom.momentFrom = moment(Date.now())
        .hours(time[0])
        .minutes(time[1]);

      time = hourDom.to.split(":");
      hourDom.momentTo = moment(Date.now())
        .hours(time[0])
        .minutes(time[1]);

      weekdays[weekdaysIds[hourDom.weekday]].hours.push(hourDom);
    }

    for (let day of Object.values(weekdays)) {
      day.hours = day.hours.sort((a, b) => {
        let ax = parseInt(a.from.substring(0, 2));
        let ab = parseInt(b.from.substring(0, 2));

        return ax < ab ? -1 : 1;
      });
    }

    setOpenHoursDom(weekdays);
  }, [openHours]);

  const handleOk = () => {
    let newSchedules = Object.values(openHoursDom)
      .map(day =>
        day.hours.map(hour => ({
          id: hour.id,
          from: hour.from,
          to: hour.to,
          weekday: hour.weekday
        }))
      )
      .flat();

    dispatch(
      updateSchedules({
        schedules: newSchedules,
        restaurantBranchId: restaurant.restaurant_branches[0].id
      })
    );
  };

  const handleCancel = () => {
    showModal();
  };

  const removeHour = (day, id) => e => {
    let newOpenHoursDom = { ...openHoursDom };
    newOpenHoursDom[day].hours.splice(id, 1);
    setOpenHoursDom(newOpenHoursDom);
  };

  const addHour = e => {
    let newOpenHoursDom = { ...openHoursDom };

    let hourDom = Object.assign({}, newHour);

    let time = hourDom.from.split(":");
    hourDom.momentFrom = moment(Date.now())
      .hours(time[0])
      .minutes(time[1]);

    time = hourDom.to.split(":");
    hourDom.momentTo = moment(Date.now())
      .hours(time[0])
      .minutes(time[1]);

    newOpenHoursDom[weekdaysIds[newHour.weekday]].hours.push(hourDom);

    newOpenHoursDom[weekdaysIds[newHour.weekday]].hours.sort((a, b) => {
      let ax = parseInt(a.from.substring(0, 2));
      let ab = parseInt(b.from.substring(0, 2));

      return ax < ab ? -1 : 1;
    });

    setOpenHoursDom(newOpenHoursDom);
  };

  const onChangeTimePicker = (type, day, id) => time => {
    if (day !== undefined && id !== undefined) {
      let newOpenHoursDom = { ...openHoursDom };

      let hourDom = openHoursDom[day].hours[id];

      hourDom[type] = time.format("HH:mm");

      let momentType = "moment" + type.charAt(0).toUpperCase() + type.slice(1);

      hourDom[momentType] = time;

      setOpenHoursDom(newOpenHoursDom);
    } else {
      setNewHour({ ...newHour, [type]: time.format("HH:mm") });
    }
  };

  const onChangeDay = value => {
    setNewHour({ ...newHour, weekday: value });
  };

  const title = (
    <div className="dashboard-modal-form-title">
      <h2>Open Hours</h2>
      <span>Manage the open hours for this location</span>
    </div>
  );

  return (
    <div>
      <Modal
        visible={visible}
        title="Title"
        onOk={handleOk}
        onCancel={handleCancel}
        wrapClassName="dashboard-modal"
        centered={true}
        title={title}
        width={715}
        bodyStyle={{
          padding: 0
        }}
        footer={
          <div className="dashboard-modal-form-footer">
            <CustomButton
              key="back"
              type="secondary"
              onClick={handleCancel}
              className="btn-text"
            >
              Don't Save & Close
            </CustomButton>
            <CustomButton
              key="submit"
              type="primary"
              onClick={handleOk}
              className="btn-text"
              loading={loading}
            >
              Save
            </CustomButton>
          </div>
        }
      >
        <Form
          layout="vertical"
          className="dashboard-modal-form dashboard-modal-form-open-hours"
        >
          <div className="row row-inputs border-b">
            <Form.Item>
              <Select onChange={onChangeDay}>
                <Select.Option value="1">Monday</Select.Option>
                <Select.Option value="2">Tuesday</Select.Option>
                <Select.Option value="3">Wednesday</Select.Option>
                <Select.Option value="4">Thursday</Select.Option>
                <Select.Option value="5">Friday</Select.Option>
                <Select.Option value="6">Saturday</Select.Option>
                <Select.Option value="0">Sunday</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item className="time-picker">
              <TimePicker
                use12Hours
                format="h:mm a"
                placeholder="Select"
                onChange={onChangeTimePicker("from")}
              />
              <span className="time-picker-middle">to</span>
              <TimePicker
                use12Hours
                format="h:mm a"
                placeholder="Select"
                onChange={onChangeTimePicker("to")}
              />
            </Form.Item>

            <Form.Item>
              <CustomButton type="primary" className="btn-text" onClick={addHour}>
                Add Open Hours
              </CustomButton>
            </Form.Item>
          </div>

          <div className="row-hours-headers border-b">
            <div className="title-header">Weekday</div>
            <div className="title-header">Open Hours</div>
          </div>

          {/*
                    <div className="row row-hours border-b">
            <Form.Item>Monday</Form.Item>

            <Form.Item className="time-picker">
              <TimePicker use12Hours format="h:mm a" placeholder="Select" />
              <span className="time-picker-middle">to</span>
              <TimePicker use12Hours format="h:mm a" placeholder="Select" />
            </Form.Item>

            <Form.Item>
              <div className="hour-info">
                4.5 hours
                <CustomButton type="tertiary" className="btn-icon">
                  <Icon type="close" />
                </CustomButton>
              </div>
            </Form.Item>
          </div>
          */}

          {Object.values(openHoursDom).map((day, id) => {
            if (!day.hours.length) return "";

            let hours = day.hours.map((hour, num) => {
              if (num === 0) return "";

              return (
                <div
                  className="row row-hours border-b"
                  key={day.weekday + hour.from + hour.to}
                >
                  <Form.Item></Form.Item>

                  <Form.Item className="time-picker">
                    <TimePicker
                      use12Hours
                      format="h:mm a"
                      placeholder="Select"
                      value={hour.momentFrom}
                      onChange={onChangeTimePicker("from", day.id, num)}
                    />
                    <span className="time-picker-middle">to</span>
                    <TimePicker
                      use12Hours
                      format="h:mm a"
                      placeholder="Select"
                      value={hour.momentTo}
                      onChange={onChangeTimePicker("to", day.id, num)}
                    />
                  </Form.Item>

                  <Form.Item>
                    <div className="hour-info">
                      {Math.floor((hour.momentTo - hour.momentFrom) / 360000) / 10 + " "}
                      hours
                      <CustomButton
                        type="tertiary"
                        className="btn-icon"
                        onClick={removeHour(day.id, num)}
                      >
                        <Icon type="close" />
                      </CustomButton>
                    </div>
                  </Form.Item>
                </div>
              );
            });
            return [
              <div
                className="row row-hours border-b"
                key={day.weekday + day.hours[0].from + day.hours[0].to}
              >
                <Form.Item>{day.day}</Form.Item>

                <Form.Item className="time-picker">
                  <TimePicker
                    use12Hours
                    format="h:mm a"
                    placeholder="Select"
                    value={day.hours[0].momentFrom}
                    onChange={onChangeTimePicker("from", day.id, 0)}
                  />
                  <span className="time-picker-middle">to</span>
                  <TimePicker
                    use12Hours
                    format="h:mm a"
                    placeholder="Select"
                    value={day.hours[0].momentTo}
                    onChange={onChangeTimePicker("to", day.id, 0)}
                  />
                </Form.Item>

                <Form.Item>
                  <div className="hour-info">
                    {Math.floor(
                      (day.hours[0].momentTo - day.hours[0].momentFrom) / 360000
                    ) /
                      10 +
                      " "}
                    hours
                    <CustomButton
                      type="tertiary"
                      className="btn-icon"
                      onClick={removeHour(day.id, 0)}
                    >
                      <Icon type="close" />
                    </CustomButton>
                  </div>
                </Form.Item>
              </div>,
              hours
            ];
          })}
        </Form>
      </Modal>
    </div>
  );
};

export default ModalOpenHours;
