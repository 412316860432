import React, { useState } from "react";

import { connect } from "react-redux";
import { setRestaurantForm } from "../../../../actions/restaurantActions";

import { Modal, Input, Form, Icon, Select, Checkbox, Tag, message } from "antd";
import CustomButton from "../../../common/CustomButton";

const socials = {
  email: {
    id: "email",
    name: "Email"
  },
  twitter: {
    id: "twitter",
    name: "Twitter"
  },
  website: {
    id: "website",
    name: "Website"
  }
};

const ModalNewRestaurantCuisines = ({
  cuisines,
  restaurant,
  setRestaurantForm,
  ...rest
}) => {
  const onAddCuisine = value => {
    const newCuisine = cuisines.find(cuisine => cuisine.slug === value);

    if (restaurant.cuisines.find(el => el.slug === newCuisine.slug)) {
      message.warning("That cuisine is already assigned to this restaurant");
      return;
    }

    const newCuisines = [...restaurant.cuisines, newCuisine];

    if (newCuisines.length > 5) {
      message.warning("You can only assign 5 cuisines at the same time");
      return;
    }

    const newRestaurant = { ...restaurant };
    newRestaurant.cuisines = newCuisines;

    setRestaurantForm(newRestaurant);
  };

  const onRemoveCuisine = index => {
    const newRestaurant = { ...restaurant };
    delete newRestaurant.cuisines[index];

    setRestaurantForm(newRestaurant);
  };

  return (
    <div className="row row-cuisines border-b">
      <Form.Item label="Cuisines & Attributes" className="item item-cuisines">
        <Select
          size="large"
          className="select-city"
          defaultValue={"Select"}
          onChange={onAddCuisine}
        >
          {cuisines.map(cuisine => (
            <Select.Option key={cuisine.slug} value={cuisine.slug}>
              {cuisine.name}
            </Select.Option>
          ))}
        </Select>
        {restaurant.cuisines && restaurant.cuisines.length ? (
          <div className="cuisines-list">
            {restaurant.cuisines.map((cuisine, index) => (
              <Tag
                closable
                color="#0166a4"
                className="single-cuisine"
                onClose={() => onRemoveCuisine(index)}
              >
                {cuisine.name}
              </Tag>
            ))}
          </div>
        ) : (
          <span>Select up to 5. Your location(s) will list under each one</span>
        )}
      </Form.Item>
    </div>
  );
};

const mapStateToProps = state => ({
  cuisines: state.cuisine.cuisines,
  restaurant: state.restaurant.restaurantForm
});

export default connect(
  mapStateToProps,
  { setRestaurantForm }
)(ModalNewRestaurantCuisines);
