import cloneDeep from "lodash.clonedeep";

import {
  MENUS_SET_ALL,
  MENUS_SET_MENUS,
  MENUS_SET_CATEGORIES,
  MENUS_SET_ITEMS,
  MENUS_SET_OPTIONS,
  MENUS_UPDATE_CATEGORIES
} from "../actions/types";

const initialState = {
  menus: {},
  categories: {},
  items: {},
  options: {},
  editing: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case MENUS_SET_ALL: {
      const { menus, categories, items, options } = action.payload;

      return {
        ...state,
        menus,
        categories,
        items,
        options
      };
    }

    case MENUS_SET_MENUS: {
      const newMenus = action.payload;

      const menus = { ...state.menus };

      newMenus.forEach(menu => {
        if (menus[menu.id]) {
          menus[menu.id] = { ...menus[menu.id], ...menu };
          if (menu.delete) {
            // Delete cat
            delete menus[menu.id];
            // Delete reference from parent
          }
        } else {
          menus[menu.id] = { ...menu };
        }
      });

      return {
        ...state,
        menus
      };
    }

    case MENUS_SET_CATEGORIES: {
      const newCategories = action.payload;

      const menus = { ...state.menus };
      const categories = { ...state.categories };

      newCategories.forEach(cat => {
        if (categories[cat.id]) {
          categories[cat.id] = { ...categories[cat.id], ...cat };
          if (cat.delete) {
            // Delete cat
            delete categories[cat.id];
            // Delete reference from parent
            menus[cat.menu_id].children = menus[cat.menu_id].children.filter(
              el => el !== cat.id
            );
          }
        } else {
          // Add a new cat
          categories[cat.id] = { ...cat };
          menus[cat.menu_id].children = [
            ...menus[cat.menu_id].children,
            cat.id
          ];
        }
      });

      return {
        ...state,
        menus,
        categories
      };
    }

    case MENUS_SET_ITEMS: {
      const newItems = action.payload;

      const items = { ...state.items };
      const categories = { ...state.categories };

      newItems.forEach(item => {
        if (items[item.id]) {
          items[item.id] = { ...items[item.id], ...item };
          if (item.delete) {
            // Delete item
            delete items[item.id];
            // Delete reference from parent
            categories[item.menu_category_id].children = categories[
              item.menu_category_id
            ].children.filter(el => el !== item.id);
          }
        } else {
          // Add a new item
          items[item.id] = { ...item };
          categories[item.menu_category_id].children = [
            ...categories[item.menu_category_id].children,
            item.id
          ];
        }
      });

      return {
        ...state,
        items
      };
    }

    case MENUS_SET_OPTIONS: {
      const newOptions = action.payload;

      const options = { ...state.options };
      const items = { ...state.items };

      newOptions.forEach(item => {
        if (options[item.id]) {
          options[item.id] = { ...options[item.id], ...item };
          if (item.delete) {
            // Delete item
            delete options[item.id];
            // Delete reference from parent
            items[item.menu_item_id].children = items[
              item.menu_item_id
            ].children.filter(el => el !== item.id);
          }
        } else {
          // Add a new item
          options[item.id] = { ...item };
          items[item.menu_item_id].children = [
            ...items[item.menu_item_id].children,
            item.id
          ];
        }
      });

      return {
        ...state,
        options
      };
    }

    default:
      return state;
  }
}
