import {
  SET_AUTH_MODAL,
  SET_LOADING,
  RESET_LOADING,
  LAYOUT_SET_MODAL_TYPE
} from "../actions/types";

const initialState = {
  modalType: {},
  authModal: "none",
  loading: false,
  loadingType: "none"
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_AUTH_MODAL:
      return {
        ...state,
        authModal: action.payload
      };

    case SET_LOADING:
      const { loading, loadingType } = action.payload;

      return {
        ...state,
        loading,
        loadingType
      };

    case RESET_LOADING:
      return {
        ...state,
        loading: initialState["loading"],
        loadingType: initialState["loadingType"]
      };

    case LAYOUT_SET_MODAL_TYPE:
      const modalType = { ...state.modalType };

      modalType[action.payload.type] = action.payload;

      if (action.payload.delete) {
        delete modalType[action.payload.type];
      }

      return {
        ...state,
        modalType
      };

    default:
      return state;
  }
}
