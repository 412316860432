import React, { Component } from "react";

import { connect } from "react-redux";
import { selectRestaurant } from "../../../../actions/restaurantActions";

import { Menu, Input, Select } from "antd";

import CustomButton from "../../../common/CustomButton";
import IconCustom from "../../../common/IconCustom";

import ModalNewRestaurant from "./ModalNewRestaurant";
import ModalOpenHours from "./ModalOpenHours";
import MainRestaurantListItem from "./MainRestaurantListItem";

class Main extends Component {
  state = {
    current: "all",
    openRestaurantModal: false,
    openHoursModal: false,
    restaurantSelected: {},
    searchQuery: ""
  };

  handleClick = e => {
    this.setState({ current: e.key });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  showModal = (restaurantSelected, modalType) => e => {
    this.props.selectRestaurant(restaurantSelected.id);

    this.setState({
      [modalType]: !this.state[modalType],
      restaurantSelected
    });
  };

  render() {
    const {
      openRestaurantModal,
      openHoursModal,
      restaurantSelected,
      searchQuery
    } = this.state;
    const { restaurants } = this.props;

    return (
      <div className="content border-r">
        <ModalNewRestaurant
          visible={openRestaurantModal}
          showModal={this.showModal({}, "openRestaurantModal")}
          restaurant={restaurantSelected}
        />
        <ModalOpenHours
          visible={openHoursModal}
          showModal={this.showModal({}, "openHoursModal")}
          restaurant={restaurantSelected}
        />

        <div className="menu border-b">
          <Menu
            onClick={this.handleClick}
            selectedKeys={[this.state.current]}
            mode="horizontal"
            className="nav-menu"
          >
            <Menu.Item key="all">
              <span>All Restaurants</span>
            </Menu.Item>
            {/*
                        <Menu.Item key="chains">
              <span>Chains</span>
            </Menu.Item>
            */}
          </Menu>

          <span className="number-restaurants">
            {restaurants.length} Restaurants
          </span>

          <CustomButton
            type="primary"
            style={{ fontSize: 14, width: 171, height: 34 }}
            onClick={this.showModal({}, "openRestaurantModal")}
            className="btn-text"
          >
            Add new Restaurant
          </CustomButton>
        </div>

        <div className="dash-header border-b">
          <span className="dash-header-item-location">Name & Location(s)</span>
          <span>Delivery Available</span>
          <span>Featured</span>
          <span>
            <IconCustom icon="noun-hearth" />
          </span>
          <div className="inputs">
            <Input
              className="search-bar-input"
              placeholder="Search Restaurants"
              name="searchQuery"
              prefix={<IconCustom icon="noun-search" />}
              value={searchQuery}
              onChange={this.onChange}
            />
            <Select
              size="large"
              className="select-city"
              defaultValue={
                <span>
                  <span className="font-m">All Cities</span>
                </span>
              }
            >
              <Option key="kingston">Kingston</Option>
            </Select>
          </div>
        </div>

        <div className="list restaurant-list">
          {restaurants.map((restaurant, index) => {
            if (
              searchQuery !== "" &&
              !restaurant.name.match(new RegExp(searchQuery, "i"))
            )
              return "";

            return (
              <MainRestaurantListItem
                key={restaurant.slug}
                showModal={this.showModal}
                restaurant={restaurant}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  restaurants: state.restaurant.restaurants
});

export default connect(
  mapStateToProps,
  { selectRestaurant }
)(Main);
