import React, { Component } from "react";

import { connect } from "react-redux";
import { updateFavorites } from "../../../../actions/authActions";
import PropTypes from "prop-types";

import { withRouter, Link } from "react-router-dom";
import CustomButton from "../../../common/CustomButton";
import IconCustom from "../../../common/IconCustom";
import OpenTag from "../../../common/OpenTag";
import { Skeleton, Popover, message } from "antd";

import classNames from "classnames";

class RestaurantItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticated: false,
      favorite: false,
      phoneOpen: false
    };
  }

  onClickMenu = () => {
    if (this.props.restaurant.loading) return;

    this.props.history.push({
      pathname: "/menus/" + this.props.restaurant.slug,
      state: { prevPath: this.props.location.pathname }
    });
  };

  onClickMap = e => {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.restaurant.loading) return;

    this.props.history.push({
      pathname: "/menus/" + this.props.restaurant.slug,
      search: "?mapOpen=true",
      state: { prevPath: this.props.restaurant.location }
    });
  };

  onClickFavorites = active => e => {
    e.preventDefault();
    e.stopPropagation();

    if (!this.props.isAuthenticated) {
      message.warning("Please sign up or login to add Favourites");
      return;
    }

    const favoriteData = {
      favorite_type: "Restaurant",
      favorite_type_id: this.props.restaurant.id,
      active
    };

    this.props.updateFavorites(this.props.username, favoriteData);

    this.setState({
      favorite: active
    });
  };

  onClickOpenTag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  onClickPhone = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ phoneOpen: !this.state.phoneOpen });
  };

  componentDidMount() {
    this.setState({
      authenticated: this.props.isAuthenticated,
      favorite: this.props.favorite
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.isAuthenticated !== prevProps.isAuthenticated) {
      this.setState({ authenticated: this.props.isAuthenticated });
    }

    if (this.props.favorite !== prevProps.favorite) {
      this.setState({ favorite: this.props.favorite });
    }
  }

  render() {
    const { authenticated, favorite, phoneOpen } = this.state;
    const {
      loading,
      name,
      address,
      id,
      open_schedule,
      schedules,
      slug,
      phone
    } = this.props.restaurant;

    return (
      <div
        className="restaurant-item border-x-lr"
        style={this.props.style}
        onClick={this.onClickMenu}
      >
        <Skeleton title={false} loading={loading} active>
          <span className="restaurant-title">{name}</span>
          <small className="restaurant-dir">{address}</small>

          <OpenTag schedules={schedules} openSchedule={open_schedule} />

          <div className="btn-list">
            {authenticated && favorite ? (
              <CustomButton
                type="tertiary"
                className="btn-fav"
                onClick={this.onClickFavorites(false)}
              >
                <IconCustom icon="noun-hearth" />
              </CustomButton>
            ) : (
              <CustomButton
                type="tertiary"
                className="btn-fav"
                onClick={this.onClickFavorites(true)}
              >
                <IconCustom icon="noun-hearth-outline" />
              </CustomButton>
            )}

            <CustomButton
              type={phoneOpen ? "primary" : "tertiary"}
              className="btn-call"
              onClick={this.onClickPhone}
            >
              <IconCustom icon="noun-phone" />
            </CustomButton>

            <div
              className={classNames("phone-number-content", {
                show: phoneOpen
              })}
            >
              <a href={`tel:${phone}`}>{phone}</a>
            </div>

            <Link
              to={{
                pathname: `/menus/${slug}`,
                search: "?mapOpen=true",
                state: { prevPath: this.props.location.pathname }
              }}
            >
              <CustomButton type="tertiary" className="btn-map">
                Map
              </CustomButton>
            </Link>

            <Link
              to={{
                pathname: `/menus/${slug}`,
                state: { prevPath: this.props.location.pathname }
              }}
            >
              <CustomButton type="primary" className="btn-menus">
                Menus
              </CustomButton>
            </Link>
          </div>
        </Skeleton>
      </div>
    );
  }
}

RestaurantItem.propTypes = {
  isAuthenticated: PropTypes.bool,
  username: PropTypes.string,
  favorite: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  username: state.auth.user.username
});

export default connect(
  mapStateToProps,
  { updateFavorites }
)(withRouter(RestaurantItem));
