import React, { useState } from "react";

import { Link, withRouter } from "react-router-dom";

import CustomButton from "../../../common/CustomButton";
import IconCustom from "../../../common/IconCustom";
import classNames from "classnames";

const MainRestaurantListItem = ({ restaurant, showModal, ...rest }) => {
  const [phoneOpen, setPhoneOpen] = useState(false);

  return (
    <div className="restaurant" {...rest}>
      <div className="title border-b">
        <h3>{restaurant.name}</h3>
        <div className="buttons">
          <CustomButton
            type="secondary"
            onClick={showModal(restaurant, "openRestaurantModal")}
          >
            Edit
          </CustomButton>

          <Link
            to={{
              pathname: `/menus/${restaurant.slug}`,
              search: "?editing=true",
              state: { prevPath: "/dashboard" }
            }}
          >
            <CustomButton type="secondary">Edit Menu</CustomButton>
          </Link>
        </div>
      </div>

      <div className="restaurant-locations">
        {restaurant.restaurant_branches.map((loc, index2) => (
          <div className="location border-b" key={index2}>
            <h5>{loc.location.address}</h5>
            <span>?</span>
            <span>{restaurant.featured_listing}</span>
            <span>{restaurant.active_favorites.length}</span>
            <div className="buttons">
              {/*
                            <CustomButton className="btn-text" type="tertiary">
                People
              </CustomButton>
              */}

              <CustomButton
                className="btn-text"
                type="tertiary"
                onClick={showModal(restaurant, "openHoursModal")}
              >
                Open Hours
              </CustomButton>
              <CustomButton
                className="btn-icon"
                type={phoneOpen ? "primary" : "tertiary"}
                onClick={() => setPhoneOpen(!phoneOpen)}
              >
                <IconCustom icon="noun-phone" />
              </CustomButton>

              <div
                className={classNames("phone-number-content", {
                  show: phoneOpen
                })}
              >
                <a href={`tel:${loc.phone}`}>{loc.phone}</a>
              </div>

              <Link
                to={{
                  pathname: `/menus/${restaurant.slug}`,
                  search: "?mapOpen=true",
                  state: { prevPath: rest.location.pathname }
                }}
              >
                <CustomButton type="tertiary" className="btn-map btn-text">
                  Map
                </CustomButton>
              </Link>
              {/* 
            <CustomButton className="btn-icon" type="tertiary">
              <IconCustom icon="cog-solid" />
            </CustomButton>
            */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withRouter(MainRestaurantListItem);
