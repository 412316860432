import React from "react";

import { Popover } from "antd";
import classNames from "classnames";
import RestaurantSchedules from "./RestaurantSchedules";

const OpenTag = ({ openSchedule, schedules, ...props }) => {
  const onClickOpenTag = e => e.stopPropagation();

  return (
    <div className="open-tag" onClick={onClickOpenTag}>
      <div
        className={classNames("tag", {
          open: openSchedule && openSchedule.open
        })}
      >
        <Popover
          placement="bottomRight"
          content={<RestaurantSchedules schedules={schedules} />}
          trigger="click"
          className="popover"
        >
          <Popover
            placement="left"
            content={
              <div className="open-tag-popover">
                {openSchedule ? openSchedule.msg : ""}
              </div>
            }
            trigger="hover"
            className="popover"
          >
            <div className="open-tag-text">
              <span>OPEN</span>
            </div>
          </Popover>
        </Popover>
      </div>
    </div>
  );
};

export default OpenTag;
