import React from "react";

import "./Footer.less";

const Footer = () => {
  return (
    <footer>
      <ul>
        <li>&copy; 2019</li>
        <li>
          <a href="https://docs.google.com/document/d/12lGIeZCtHzOTLsUnF58_8djVBFyoRTYy1RibSQc8PBI/edit?usp=sharing">
            Legal
          </a>
        </li>
        <li>
          <a href="mailto:matt@menusite.com">matt@menusite.com</a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
