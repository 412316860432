import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import ReactGA from "react-ga";

import {
  SET_ERRORS,
  SET_CURRENT_USER,
  SET_AUTH_MODAL,
  SET_LOADING,
  SET_PROFILE,
  SET_FAVORITE_ITEM
} from "./types.js";

import { message } from "antd";
import responsiveObserve from "antd/lib/_util/responsiveObserve";

// Register user
export const registerUser = userData => async dispatch => {
  try {
    // Set loading
    dispatch({
      type: SET_LOADING,
      payload: {
        loading: true,
        loadingType: "register"
      }
    });

    // Register
    const response = await axios.post("/api/v1/auth/register", userData);

    // Send a message and redirect to auth modal
    if (response.data.user) {
      dispatch({
        type: SET_LOADING,
        payload: {
          loading: false,
          loadingType: "register"
        }
      });

      message.success("Your account has been successfully created, please login");

      dispatch({
        type: SET_AUTH_MODAL,
        payload: "login"
      });
    }
  } catch (err) {
    dispatch({
      type: SET_LOADING,
      payload: {
        loading: false,
        loadingType: "register"
      }
    });
    dispatch({
      type: SET_ERRORS,
      payload: {
        errors: err.response.data.errors,
        errorType: "register"
      }
    });
  }
};

// Login - Get User Token
export const loginUser = userData => async dispatch => {
  try {
    const response = await axios.post("/api/v1/auth/login", userData);
    console.log(response.data);
    // Save to localStorage
    const { token, profile, favorites } = response.data;
    // Set token to ls
    localStorage.setItem("jwtToken", token);
    // Set token to Auth header
    setAuthToken(token);
    // Decode token to get user data
    const decoded = jwt_decode(token);
    // Set current user
    dispatch(setCurrentUser(decoded));
    // Set profile

    // Google analytics
    ReactGA.set({
      userId: decoded.unique_user_token
    });

    // Close auth modal
    dispatch({
      type: SET_AUTH_MODAL,
      payload: "none"
    });
  } catch (err) {
    if (err.response) {
      if (err.response.data.error) message.error(err.response.data.error);
    }
  }
};

// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set  current user to {} wich will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  window.location.href = "/";
};

// Set logged in user
export const setCurrentUser = decoded => async dispatch => {
  dispatch({
    type: SET_CURRENT_USER,
    payload: decoded
  });

  try {
    const response = await axios.get(`/api/v1/profiles/${decoded.username}`);

    const { profile, favorites } = response.data;

    dispatch({
      type: SET_PROFILE,
      payload: {
        profile,
        favorites
      }
    });
  } catch (e) {
    console.log(e.data);
  }
};

// Update current user profile
export const updateProfile = userData => async dispatch => {
  const { name, homePhone, mobilePhone, avatar } = userData;

  try {
    const response = await axios.put(`/api/v1/profile/${userData.username}`, {
      name,
      home_phone: homePhone,
      mobile_phone: mobilePhone,
      avatar
    });

    const { profile } = response.data;

    dispatch({
      type: SET_PROFILE,
      payload: profile
    });
  } catch (e) {
    console.log(e);
  }
};

// Add or delete from favorites
export const updateFavorites = (username, favorite) => async dispatch => {
  try {
    const response = await axios.put(`/api/v1/profiles/${username}/favorites`, favorite);

    dispatch({
      type: SET_FAVORITE_ITEM,
      payload: response.data.favorite
    });
  } catch (e) {
    console.log(e.data);
  }
};
