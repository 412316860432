import React, { Component } from "react";

import "./CustomButton.less";

import { Button } from "antd";
import PropTypes from "prop-types";
import classNames from "classnames";

class CustomButton extends Component {
  render() {
    let buttonClass;

    switch (this.props.type) {
      case "primary":
        buttonClass = "custom-btn-primary";
        break;
      case "secondary":
        buttonClass = "custom-btn-secondary";
        break;
      case "tertiary":
        buttonClass = "custom-btn-tertiary";
        break;
      default:
        buttonClass = "custom-btn-primary";
        break;
    }

    return (
      <Button
        {...this.props}
        className={classNames(this.props.className, "custom-btn", buttonClass)}
      >
        {this.props.children}
      </Button>
    );
  }
}

CustomButton.propTypes = {
  type: PropTypes.string.isRequired
};

CustomButton.defaultProps = {
  htmlType: "button"
};

export default CustomButton;
