import React, { Component, createRef } from "react";

import { connect } from "react-redux";
import { UPDATE_RESTAURANT_MENUS } from "../../../actions/types";
import { reorder } from "../../../utils/array";

import "./NavMenu.less";

class NavMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ammount: Infinity,
      itemList: [],
      activeItem: {},
      oldIndex: null,
      newIndex: null,
      selected: ""
    };

    this.items = [];
  }

  onMouseEnter = e => {
    if (this.state.oldIndex === null) return;

    if (this.state.newIndex !== parseInt(e.target.dataset.index)) {
      this.setState({
        newIndex: parseInt(e.target.dataset.index)
      });
    }
  };

  onMouseDown = e => {
    e.preventDefault();
    let elmt, pos1, pos2, pos3, pos4, onMouseUp, onMouseMove;

    elmt = document.querySelector(".draggable-item-helper");

    elmt.classList.toggle("show");

    elmt.style.top = e.clientY - elmt.clientHeight / 2 + "px";
    elmt.style.left = e.clientX - elmt.clientWidth / 2 + "px";

    let index = parseInt(e.target.dataset.index);

    this.setState({
      activeItem: this.state.itemList[index],
      oldIndex: index,
      newIndex: index
    });

    onMouseUp = e => {
      elmt.classList.toggle("show");

      // Clean
      this.setState({
        activeItem: {},
        oldIndex: null,
        newIndex: null
      });

      // Events
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("mousemove", onMouseMove);

      // Reorder
      // reorder();
    };

    onMouseMove = e => {
      e = e || window.event;
      e.preventDefault();

      // set the element's new position:
      elmt.style.top = e.clientY - elmt.clientHeight / 2 + "px";
      elmt.style.left = e.clientX - elmt.clientWidth / 2 + "px";
    };

    // Events
    document.addEventListener("mouseup", onMouseUp);
    document.addEventListener("mousemove", onMouseMove);
  };

  calculateListOverflow = () => {
    const more = document.querySelector(".more-expand");
    let stopWidth = 0;
    if (more) stopWidth = more.offsetWidth;

    const primaryWidth = document.querySelector(".nav-menu").offsetWidth - 74;
    const listItems = document.querySelectorAll(".nav-menu-item");
    let index = 0;

    for (let item of listItems) {
      if (primaryWidth >= stopWidth + item.offsetWidth) {
        stopWidth += item.offsetWidth;
        index++;
      } else {
        break;
      }
    }

    this.setState({
      ammount: index
    });
  };

  componentDidMount() {
    this.setState({
      itemList: this.props.items,
      selected: this.props.tabSelected
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.itemList.length) {
      this.calculateListOverflow();
    }

    if (this.state.itemList !== this.props.restaurant.menus) {
      this.calculateListOverflow();
      this.setState({
        itemList: this.props.restaurant.menus
      });
    }

    if (this.state.newIndex !== prevState.newIndex && this.state.newIndex !== null) {
      let newRestaurant = {
        ...this.props.restaurant,
        menus: reorder(this.state.itemList, this.state.oldIndex, this.state.newIndex)
      };

      this.props.dispatch({
        type: UPDATE_RESTAURANT_MENUS,
        payload: newRestaurant
      });

      this.setState({
        //itemList: reorder(this.state.itemList, this.state.oldIndex, this.state.newIndex),
        oldIndex: this.state.newIndex,
        ammount: this.state.ammount + 1
      });
    }

    if (this.props.tabSelected !== prevProps.tabSelected) {
      this.setState({
        selected: this.props.tabSelected
      });
    }
  }

  render() {
    const { itemList, newIndex, activeItem, ammount, selected } = this.state;

    let itemsArr = [];
    let hiddenItemsArr = [];

    for (let i = 0; i < itemList.length; i++) {
      let item = itemList[i];

      let className = i === newIndex ? " dragging" : "";

      if (i >= ammount - this.props.staticItems.length) {
        hiddenItemsArr.push(
          <li
            className={"nav-menu-item-hidden " + className}
            key={item.id}
            data-index={i}
            onMouseDown={this.onMouseDown}
            ref={el => (this.items[i] = el)}
          >
            <div className="text" data-index={i} onMouseEnter={this.onMouseEnter}>
              {item.name}
            </div>
          </li>
        );
      } else {
        itemsArr.push(
          <li
            className={"nav-menu-item" + className}
            key={item.id}
            data-index={i}
            onMouseDown={this.onMouseDown}
            ref={el => (this.items[i] = el)}
          >
            <div className="text" data-index={i} onMouseEnter={this.onMouseEnter}>
              {item.name}
            </div>
          </li>
        );
      }
    }

    let $items;

    if (hiddenItemsArr.length) {
      $items = [
        ...itemsArr,
        <li className="more" key="more-btn">
          <div className="nav-menu-item more-expand">...</div>
          <ul className="secondary">{hiddenItemsArr}</ul>
        </li>
      ];
    } else {
      $items = [...itemsArr];
    }

    return (
      <ul className="nav-menu border-x border-b">
        {this.props.staticItems.map(el => (
          <li className="nav-menu-item" key={el.id}>
            <div className="text">{el.name}</div>
          </li>
        ))}
        {$items}
        <li className="draggable-item-helper">{activeItem.name}</li>
      </ul>
    );
  }
}

const mapStateToProps = state => ({
  restaurant: state.restaurant.restaurant
});

export default connect(
  mapStateToProps,
  null
)(NavMenu);
