import React, { Component } from "react";
import { Menu, Input, Icon } from "antd";

import CustomButton from "../../../common/CustomButton";
import IconCustom from "../../../common/IconCustom";

import "./Areas.less";

class Areas extends Component {
  state = {
    current: "all"
  };

  handleClick = e => {
    this.setState({ current: e.key });
  };

  render() {
    return (
      <div className="content border-r">
        <div className="menu border-b">
          <Menu
            onClick={this.handleClick}
            selectedKeys={[this.state.current]}
            mode="horizontal"
          >
            <Menu.Item key="all">
              <span>All Areas</span>
            </Menu.Item>
          </Menu>

          <span className="number-restaurants">3 cities</span>

          <CustomButton type="primary" style={{ fontSize: 14 }}>
            Add New City
          </CustomButton>
        </div>

        <div className="dash-header border-b dash-header-area">
          <span>City, Region, Country</span>
          <span>Time Zone</span>
          <span>Restaurants</span>
          <span>URL</span>
        </div>

        <div className="list areas-list">
          {areas.map((el, index) => (
            <div className="area border-b">
              <span>{el.city}</span>
              <span>{el.timezone}</span>
              <span>{el.restaurants}</span>
              <span>{el.url}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Areas;

const areas = [
  {
    city: "Kingston, Ontario, Canada",
    timezone: "UTC-5",
    restaurants: "222",
    url: "menusite.com/kingston/ontario"
  },
  {
    city: "Toronto, Ontario, Canada",
    timezone: "UTC-5",
    restaurants: "0",
    url: "menusite.com/toronto/ontario"
  },
  {
    city: "Paris, Île-de-France, France",
    timezone: "UTC+1",
    restaurants: "0",
    url: "menusite.com/paris/ile-de-france"
  }
];
