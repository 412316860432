import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { searchRestaurant } from "../../actions/restaurantActions";

import Lottie from "react-lottie";
import { List } from "antd";

import RestaurantItem from "../pages/landing/components/RestaurantItem";

import "./Search.less";

const Search = ({ searchQuery, ...props }) => {
  const restaurantSearch = useSelector(state => state.restaurant.restaurantSearch);
  const favorites = useSelector(state => state.auth.favorites);
  const loading = useSelector(state => state.layout.loading);
  const loadingType = useSelector(state => state.layout.loadingType);
  const initLoading = false;
  const dispatch = useDispatch();

  const [restaurants, setRestaurants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [animationLoading, setAnimationLoading] = useState(false);
  const [animationNoResult, setAnimationNoResult] = useState(false);

  useEffect(() => {
    setRestaurants(restaurantSearch);
  }, [restaurantSearch]);

  useEffect(() => {
    if (searchQuery.length >= 3) {
      dispatch(searchRestaurant(searchQuery));
    } else {
      dispatch(searchRestaurant(false));
    }
  }, [searchQuery]);

  useEffect(() => {
    if (loadingType === "searchingRestaurant") {
      setIsLoading(loading);
    }
  }, [loading]);

  useEffect(() => {
    fetch("/static_assets/json/Hat_Loader_V05_NoText.json")
      .then(a => a.json())
      .then(json => setAnimationLoading(json));

    fetch("/static_assets/json/NoResult_V01.json")
      .then(a => a.json())
      .then(json => setAnimationNoResult(json));
  }, []);

  return (
    <div id="search-container">
      <div id="search">
        {searchQuery.length < 3 ? (
          <div className="search-error">
            <span>Please input 3 characters or more to start with your search</span>
          </div>
        ) : !isLoading && !restaurants.length ? (
          <div className="search-error">
            <div className="animation">
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: animationNoResult,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                  }
                }}
              />
            </div>
          </div>
        ) : isLoading && !restaurants.length ? (
          <div className="search-error">
            <div className="animation">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: animationLoading,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                  }
                }}
              />
            </div>
          </div>
        ) : (
          <List
            loading={initLoading}
            dataSource={restaurants}
            itemLayout="horizontal"
            renderItem={restaurant => {
              return (
                <List.Item
                  style={{
                    padding: 0,
                    border: "none"
                  }}
                >
                  <RestaurantItem
                    restaurant={restaurant}
                    key={restaurant.id}
                    favorite={
                      !!(
                        restaurant.cuisines[0] &&
                        favorites[restaurant.cuisines[0].slug] &&
                        favorites[restaurant.cuisines[0].slug].restaurants[
                          restaurant.id
                        ] &&
                        favorites[restaurant.cuisines[0].slug].restaurants[restaurant.id]
                          .active
                      )
                    }
                    schedule={restaurant.open_schedule}
                    style={{
                      borderLeft: "solid 1px #eaeaea",
                      borderRight: "solid 1px #eaeaea",
                      paddingLeft: "29px",
                      paddingRight: "29px"
                    }}
                  />
                </List.Item>
              );
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Search;
