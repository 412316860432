import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import { connect } from "react-redux";
import { loginUser } from "../../../actions/authActions";

import { Form, Icon, Input, Checkbox } from "antd";
import CustomButton from "../../common/CustomButton";

class AuthModalLogin extends Component {
  constructor(props) {
    super(props);
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.loginUser({
          email: values.email,
          password: values.password
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <>
        <div className="title">
          <Icon type="heart" />
          <h3>Login to your account</h3>
        </div>
        <Form onSubmit={this.handleSubmit} className="auth-form auth-modal-form-login">
          <Form.Item className="auth-form-item">
            {getFieldDecorator("email", {
              rules: [{ required: true, message: "Please input your email!" }]
            })(
              <Input
                prefix={<Icon type="user" style={{ color: "rgb(1, 102, 164)" }} />}
                placeholder="Email"
                className="outline-input"
              />
            )}
          </Form.Item>
          <Form.Item className="auth-form-item">
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "Please input your Password!" }]
            })(
              <Input
                prefix={
                  <Icon
                    type="lock"
                    theme="filled"
                    style={{ color: "rgb(1, 102, 164)" }}
                  />
                }
                type="password"
                placeholder="Password"
                className="outline-input"
              />
            )}
          </Form.Item>
          <Form.Item className="auth-form-item buttons-container">
            <CustomButton type="secondary" htmlType="submit">
              Login
            </CustomButton>
            <CustomButton type="primary" onClick={() => this.props.changeModal("signup")}>
              Sign up
            </CustomButton>
          </Form.Item>
          <Form.Item className="auth-form-item">
            <div className="remember-password">
              {getFieldDecorator("remember", {
                valuePropName: "checked",
                initialValue: true
              })(<Checkbox>Remember me</Checkbox>)}
              <a className="login-form-forgot" href="">
                <u>Forgot password?</u>
              </a>
            </div>
          </Form.Item>
          <Form.Item className="auth-form-item dont-have-account">
            <span>
              Don't have an account?{" "}
              <small href="" onClick={() => this.props.changeModal("signup")}>
                Sign up
              </small>
            </span>
          </Form.Item>
        </Form>
      </>
    );
  }
}

const WrappedAuthModalLogin = withRouter(
  Form.create({ name: "normal_login" })(AuthModalLogin)
);

export default connect(
  null,
  { loginUser }
)(WrappedAuthModalLogin);
