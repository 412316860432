import React, { Component } from "react";

import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { searchRestaurant } from "../../actions/restaurantActions";
import classNames from "classnames";

import { Icon } from "antd";

import CustomButton from "../common/CustomButton";
import IconCustom from "../common/IconCustom";
import LoginButton from "./auth/LoginButton";
import SignUpButton from "./auth/SignUpButton";
import ProfileDropdown from "./ProfileDropdown";
import Search from "./Search";

import "./Header.less";

const WAIT_INTERVAL = 1000;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
      page: "/",
      searchQuery: "",
      mobileMenuOpen: false,
      showNotification: true,
      dashboardAccess: false
    };

    this.$header = null;
  }

  componentDidMount() {
    window.addEventListener("load", this.handler);
    window.addEventListener("scroll", this.handler);
    window.addEventListener("resize", this.handler);
    this.props.setHeaderHeight(this.$header.clientHeight);

    this.setState({
      authenticated: this.props.isAuthenticated
    });

    this.props.history.push(this.props.history.location.pathname);

    // Check header notification in localStorage
    let notificationClosed = window.localStorage.getItem("notificationClosed");

    this.setState({
      showNotification: notificationClosed ? false : true
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showNotification !== this.state.showNotification) {
      this.props.setHeaderHeight(this.$header.clientHeight);
    }

    if (this.state.page !== this.props.history.location.pathname) {
      this.setState({
        page: this.props.history.location.pathname
      });
    }

    if (this.props.isAuthenticated !== prevProps.isAuthenticated) {
      this.setState({ authenticated: this.props.isAuthenticated });
    }

    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.searchRestaurant(false);
      document.body.style.overflowY = "auto";
      this.setState({
        searchQuery: ""
      });
    }

    if (this.props.dashboardAccess !== this.state.dashboardAccess) {
      this.setState({
        dashboardAccess: this.props.dashboardAccess
      });
    }
  }

  handler = () => {
    const sticky = this.$header.clientHeight;

    if (window.pageYOffset > 0) {
      this.$header.classList.add("sticky");
    } else {
      this.$header.classList.remove("sticky");
    }
  };

  handleChange = e => {
    this.setState({ searchQuery: e.target.value });

    const logo = document.querySelector(".logo-full");

    if (e.target.value !== "") {
      document.body.style.overflowY = "hidden";
      if (logo) {
        logo.style.display = "none";
      }
    } else {
      document.body.style.overflowY = "auto";
      if (logo) {
        logo.style.display = "block";
      }
    }
  };

  handleMobileMenu = () => {
    document.querySelector(".logo-full").style.display =
      document.querySelector(".logo-full").style.display === "none" ? "block" : "none";
    this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen });
  };

  closeSearch = () => {
    const logo = document.querySelector(".logo-full");

    document.body.style.overflowY = "auto";
    if (logo) {
      logo.style.display = "block";
    }
    this.props.searchRestaurant(false);
    this.setState({
      searchQuery: ""
    });
  };

  render() {
    const {
      page,
      authenticated,
      searchQuery,
      mobileMenuOpen,
      showNotification,
      dashboardAccess
    } = this.state;

    return (
      <header
        id="header"
        style={this.props.style}
        className={classNames({ "show-notification": showNotification })}
        ref={el => (this.$header = el)}
      >
        <div className="notification">
          <span className="text-desktop">
            We're a bit behind on relaunch - menus & open hours will be updated gradually over the
            next few weeks! Missing a restaurant? Email
            <a href="mailto:matt@menusite.com" className="link">
              matt@menusite.com
            </a>
          </span>
          <div className="text-mobile">
            <strong>Welcome back students!</strong>
            <p>
              We're a bit behind on relaunch - menus & open hours will be updated gradually over the
              next few weeks!
            </p>
            <p>
              Missing a restaurant? Email
              <a href="mailto:matt@menusite.com" className="link">
                matt@menusite.com
              </a>
            </p>
          </div>
          <Icon
            type="close"
            onClick={() => {
              window.localStorage.setItem("notificationClosed", true);
              this.setState({ showNotification: !showNotification });
            }}
          />
        </div>
        <div className="header-content">
          {page !== "/" && searchQuery === "" ? (
            <CustomButton
              className="btn-return"
              type="primary"
              onClick={() => {
                this.props.history.push("/");
              }}
            >
              <IconCustom icon="arrow-left-bold" />
              View All Kingston Menus
            </CustomButton>
          ) : (
            <div className="logo-container border-r">
              <IconCustom icon="menusite-chef-simple" className="logo" />
            </div>
          )}

          <label
            className={classNames("search-bar", { searching: searchQuery !== "" })}
            style={{ cursor: "text" }}
          >
            <IconCustom icon="noun-search" />
            <input
              type="text"
              placeholder="Search Restaurants"
              value={searchQuery}
              onChange={this.handleChange}
            />
            <Icon type="close" className="icon-close" onClick={this.closeSearch} />
          </label>

          {page !== "/" && searchQuery === "" ? (
            <div className="logo-container logo-centered">
              <img src="/static_assets/img/menusite-chef-small3.png" alt="logo" className="logo" />
            </div>
          ) : (
            ""
          )}

          {authenticated ? (
            <>
              {dashboardAccess ? (
                <Link to="/dashboard" className="dashboard-link">
                  <IconCustom icon="view-grid" />
                  <span>Dashboard</span>
                </Link>
              ) : (
                ""
              )}

              <div className="profile border-l">
                <img src="/static_assets/img/avatar.png" alt="avatar" className="avatar" />@
                {this.props.username}
              </div>
              <div className="mobile-menu" onClick={this.handleMobileMenu}>
                <Icon type="menu" />
              </div>

              <ProfileDropdown
                className={classNames({ "show-mobile": mobileMenuOpen })}
                handleMobileMenu={this.handleMobileMenu}
              />
            </>
          ) : (
            <div className="auth-area">
              <SignUpButton />
              <LoginButton />
            </div>
          )}
        </div>

        {searchQuery !== "" && <Search searchQuery={searchQuery} />}
      </header>
    );
  }
}

Header.propTypes = {
  setHeaderHeight: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  username: state.auth.user.username,
  dashboardAccess: state.auth.user.dashboard_access
});

export default connect(
  mapStateToProps,
  { searchRestaurant }
)(withRouter(Header));
