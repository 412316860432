import React, { Component } from "react";
import { Menu, Input, Checkbox, Select } from "antd";
import { connect } from "react-redux";
import {
  searchCuisines,
  updateCuisines
} from "../../../actions/cuisineActions";

import DocumentMeta from "react-document-meta";

import ReactGA from "react-ga";

import Lottie from "react-lottie";
import animationLogoURI from "../../../assets/lottie/Logo_MenuSite_V02.json";

import IconCustom from "../../common/IconCustom";
import Restaurants from "./components/Restaurants";
import Favorites from "./components/Favorites";

import "./Landing.less";

class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: "all",
      cuisines: [],
      cuisineSearchQuery: "",
      checkedCuisines: {},
      animationLogo: false,
      animationLoading: false
    };

    this.$menu = null;
    this.menuOffset = 0;
  }

  handleClick = e => {
    this.setState({ tab: e.key });
  };

  onChangeSearch = e => {
    this.setState({ cuisineSearchQuery: e.target.value });
  };

  onChangeCuisineQuery = (e, slug) => {
    // Scroll to top
    const sticky = this.$menu;
    const notification = document.querySelector(".notification").clientHeight;
    window.scrollTo(0, this.menuOffset - sticky.clientHeight - notification);

    const newCheckedCuisines = Object.assign({}, this.state.checkedCuisines);

    if (e.target.checked === false) {
      delete newCheckedCuisines[slug];
    } else {
      newCheckedCuisines[slug] = true;
    }

    this.setState({
      checkedCuisines: newCheckedCuisines
    });
  };

  onChangeCuisineQueryClick = slug => {
    this.setState({
      checkedCuisines: { [slug]: true }
    });
  };

  handlerScroll = () => {
    const sticky = this.$menu;
    const restaurants = document.querySelector(".restaurants-content");

    const notification = document.querySelector(".notification").clientHeight;
    const header = document.querySelector(".header-content").clientHeight;

    if (!sticky) return;

    if (window.scrollY > this.menuOffset - sticky.clientHeight - notification) {
      sticky.style.width =
        document.querySelector("#landing").clientWidth + "px";
      sticky.classList.add("sticky");
      sticky.style.top = header + notification + "px";
      restaurants.style.marginTop = sticky.clientHeight + "px";
    } else {
      sticky.classList.remove("sticky");
      if (!restaurants) return;
      restaurants.style.marginTop = 0;
      delete sticky.style.width;

      this.menuOffset = this.$menu.offsetTop;
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.$menu = document.getElementById("listing-nav-menu");
    this.menuOffset = this.$menu.offsetTop;
    window.addEventListener("load", this.handlerScroll);
    window.addEventListener("scroll", this.handlerScroll);
    window.addEventListener("resize", this.handlerScroll);
    this.props.searchCuisines();

    if (this.props.location.search) {
      let tab = this.props.location.search.match(/tab=([^&]*)/);
      if (tab) {
        this.setState({
          tab: tab[1]
        });

        ReactGA.modalview("/favourites");
      } else {
        ReactGA.pageview("/");
      }
    } else {
      ReactGA.pageview("/");
    }

    fetch(animationLogoURI)
      .then(a => a.json())
      .then(json => this.setState({ animationLogo: json }));

    fetch("/static_assets/json/Hat_Loader_V05_NoText.json")
      .then(a => a.json())
      .then(json => this.setState({ animationLoading: json }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.search) {
      let tab = this.props.location.search.match(/tab=([^&]*)/);
      if (tab && tab[1] !== this.state.tab) {
        delete this.props.location.search;
        this.setState({
          tab: tab[1]
        });
      }
    }

    if (this.props.cuisinesUpdated === "SET_CUISINES") {
      window.scrollTo(0, 0);
      this.setState({
        cuisines: this.props.cuisines
      });

      this.props.updateCuisines("none");
    }

    if (this.state.tab !== prevState.tab) {
      if (this.state.tab === "all") {
        ReactGA.pageview("/");
      } else if (this.state.tab === "favourites") {
        ReactGA.modalview("/favourites");
      }
    }
  }

  render() {
    const {
      tab,
      cuisines,
      cuisineSearchQuery,
      checkedCuisines,
      animationLogo,
      animationLoading
    } = this.state;

    document.title = `MENUSITE - Kingston :: All Restaurants. All Menus.`;

    const meta = {
      title: "MENUSITE - Kingston :: All Restaurants. All Menus.",
      meta: {
        charset: "utf-8",
        description:
          "Whether you're too lazy to cook, you misplaced your refrigerator, or you don't know where your kitchen is - MenuSite is for you!",
        keywords:
          "Kingston menus, restaurants Kingston, menusite, menus, restaurants",
        copyright: "MenuSite"
      }
    };

    return (
      <DocumentMeta meta={meta}>
        <main className="container">
          <div className="content" id="landing">
            <div className="showcase border-x">
              <h1>
                MENU<span className="text-blue">SITE</span>
              </h1>
              <h2>All Restaurants. All Menus.</h2>

              <p>
                Whether you're{" "}
                <span className="text-blue">too lazy to cook</span>, you
                <span className="text-blue"> misplaced your refrigerator</span>,
                or you
                <span className="text-blue">
                  {" "}
                  don't know where your kitchen is
                </span>{" "}
                - MenuSite is for you!
              </p>

              <div className="logo-full">
                <Lottie
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData: animationLogo,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice"
                    }
                  }}
                />
              </div>

              {/*<IconCustom icon="menusite-chef-full-2019" className="logo-full" />*/}
            </div>

            <Menu
              selectedKeys={[this.state.tab]}
              onClick={this.handleClick}
              mode="horizontal"
              className="nav-menu border-x"
              id="listing-nav-menu"
            >
              <Menu.Item key="all">All Restaurants</Menu.Item>
              <Menu.Item key="favourites">Favourites</Menu.Item>
            </Menu>

            <div className="restaurants-content border-x border-b ">
              {tab === "all" ? (
                cuisines.length ? (
                  <div>
                    <h3 className="restaurant-zone border-x-lr">
                      Kingston Restaurant Menus
                    </h3>

                    <div className="select-menu border-x border-b">
                      <Select size="large" defaultValue="ALL MENUS">
                        {cuisines.map(cuisine => {
                          if (!cuisine.restaurants) return;
                          if (!cuisine.restaurants.length) return;

                          return (
                            <Select.Option
                              className="border-b"
                              key={cuisine.slug}
                              onClick={e =>
                                this.onChangeCuisineQueryClick(cuisine.slug)
                              }
                            >
                              {cuisine.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </div>

                    <Restaurants checkedCuisines={checkedCuisines} />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 300,
                      width: 624
                    }}
                  >
                    <div style={{ width: 100, height: 100 }}>
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: animationLoading,
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                          }
                        }}
                      />
                    </div>
                  </div>
                )
              ) : (
                <Favorites />
              )}

              <div className="restaurant-categories">
                <Input
                  className="search-bar-input"
                  placeholder="Search Cuisines"
                  prefix={<IconCustom icon="noun-search" />}
                  value={cuisineSearchQuery}
                  onChange={this.onChangeSearch}
                />

                <div className="checkeds">
                  <span>ALL MENUS</span>
                </div>

                <ul className="categories-list">
                  <li className="border-b">
                    <Checkbox
                      checked={checkedCuisines.open}
                      onChange={e => this.onChangeCuisineQuery(e, "open")}
                    >
                      CURRENTLY OPEN
                    </Checkbox>
                  </li>

                  {/* 
                  
                                    <li className="border-b">
                    <Checkbox>CURRENTLY OPEN</Checkbox>
                  </li>
                  <li className="border-b">
                    <Checkbox>DELIVERY AVAILABLE</Checkbox>
                  </li>
                  
                  */}

                  {cuisines.length ? (
                    cuisines.map(cuisine => {
                      if (
                        cuisineSearchQuery !== "" &&
                        !cuisine.name.match(new RegExp(cuisineSearchQuery, "i"))
                      )
                        return "";

                      if (!cuisine.restaurants || !cuisine.restaurants.length)
                        return "";

                      return (
                        <li className="border-b" key={cuisine.slug}>
                          <Checkbox
                            checked={checkedCuisines[cuisine.slug]}
                            onChange={e =>
                              this.onChangeCuisineQuery(e, cuisine.slug)
                            }
                            key={cuisine.slug}
                          >
                            {cuisine.name}
                          </Checkbox>
                        </li>
                      );
                    })
                  ) : (
                    <div
                      className="border-x"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 150
                      }}
                    >
                      <div style={{ width: 100, height: 100 }}>
                        <Lottie
                          options={{
                            loop: true,
                            autoplay: true,
                            animationData: animationLoading,
                            rendererSettings: {
                              preserveAspectRatio: "xMidYMid slice"
                            }
                          }}
                        />
                      </div>
                    </div>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </main>
      </DocumentMeta>
    );
  }
}

const mapStateToProps = state => ({
  cuisines: state.cuisine.cuisines,
  cuisinesUpdated: state.cuisine.cuisinesUpdated
});

export default connect(
  mapStateToProps,
  { updateCuisines, searchCuisines }
)(Landing);
