import React, { Component } from "react";
import { Modal } from "antd";
import AuthModal from "./AuthModal";
import CustomButton from "../../common/CustomButton";

class ModalLogin extends Component {
  state = { visible: false };

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleOk = e => {
    this.setState({
      visible: false
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false
    });
  };

  render() {
    return (
      <>
        <CustomButton type="tertiary" onClick={this.showModal} className="btn-login">
          Login
        </CustomButton>
        <Modal
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          centered={true}
          wrapClassName="auth-modal"
          bodyStyle={{
            padding: 0
          }}
        >
          <AuthModal
            modal="login"
            modalVisible={this.state.visible}
            closeModal={this.handleCancel}
          />
        </Modal>
      </>
    );
  }
}

export default ModalLogin;
