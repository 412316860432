import React, { Component } from "react";
import { connect } from "react-redux";
import {
  searchCuisines,
  addRestaurantsToCuisines,
  updateCuisines
} from "../../../../actions/cuisineActions";
import PropTypes from "prop-types";
import { Select, List, Skeleton } from "antd";
import getIsOpenFromSchedule from "../../../../utils/getIsOpenFromSchedule";

import RestaurantItem from "./RestaurantItem";
import CustomButton from "../../../common/CustomButton";
import IconCustom from "../../../common/IconCustom";

class Favorites extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initLoading: true,
      loading: false,
      cuisines: []
    };
  }

  componentDidMount() {
    this.setState({
      cuisines: this.props.favorites,
      initLoading: false
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.favorites !== prevProps.favorites) {
      this.setState({
        cuisines: this.props.favorites
      });
    }
  }

  render() {
    const { initLoading, loading, cuisines, favorites } = this.state;

    const loadMore = !initLoading && !loading;

    let theresFavorites = false;

    const favoritesList = Object.values(cuisines).map((cuisine, id) => {
      const restaurants = Object.values(cuisine.restaurants).filter(el => el.active);

      if (!restaurants.length) return "";

      theresFavorites = true;

      return (
        <div key={cuisine.slug}>
          <h4 className="restaurant-type-title border-x-lr">{cuisine.name}</h4>
          <List
            loading={initLoading}
            dataSource={restaurants}
            itemLayout="horizontal"
            renderItem={restaurant => {
              return (
                <List.Item style={{ border: "none", padding: 0 }}>
                  <RestaurantItem
                    cuisine={cuisine}
                    restaurant={restaurant}
                    key={restaurant.id}
                    favorite={restaurant.active}
                  />
                </List.Item>
              );
            }}
          />
        </div>
      );
    });

    return (
      <div>
        <h3 className="restaurant-zone border-x-lr">Favourites</h3>
        <div className="restaurant-type-list">
          {!theresFavorites ? (
            <div className="favourites-empty">
              <span>
                You haven't added any locations or items to your Favourites yet.
              </span>
              <span>
                Login and click <IconCustom icon="noun-hearth-outline" /> to Favourite a
                location.
              </span>
              <span>Favourites are private to you.</span>
            </div>
          ) : (
            favoritesList
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  favorites: state.auth.favorites
});

export default connect(mapStateToProps)(Favorites);
