import React, { useState, useEffect } from "react";

import { connect, useDispatch } from "react-redux";
import { setLayoutModal } from "../../../actions/layoutActions";

import { Modal, Badge, Input, Icon } from "antd";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import CustomButton from "../../common/CustomButton";
import { reorder, valuesOrdered, reverse } from "../../../utils/array";
import ClassNames from "classnames";

import "./EditCategoriesModal.less";

const getItemStyle = (isDragging, draggableStyle) => ({
  borderTop: isDragging ? "solid 1px #eaeaea" : "none",
  ...draggableStyle
});

const EditMenusModal = ({ modalType, menus, setLayoutModal, ...rest }) => {
  const [list, setList] = useState([]);
  const [editing, setEditing] = useState(null);
  const currentModal = modalType["MODAL_MENUS"];
  const visible = !!currentModal;
  const dispatch = useDispatch();

  const closeModal = () => {
    setLayoutModal({
      type: "MODAL_MENUS",
      delete: true
    });
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // Update menus
    const newMenus = reorder(
      list,
      result.source.index,
      result.destination.index
    );

    updateMenusList(newMenus);
  };

  const updateMenusList = newMenus => {
    dispatch({
      type: "MENUS_SET_MENUS",
      payload: newMenus
    });

    setList(newMenus);
  };

  const onChange = e => {
    setList(
      list.map(el => {
        if (el.id === editing) el.name = e.target.value;
        return el;
      })
    );
  };

  const onSave = () => {
    const menuEditing = list.findIndex(el => el.id === editing);

    if (menuEditing === -1) return;

    dispatch({
      type: "MENUS_SET_MENUS",
      payload: [list[menuEditing]]
    });

    setEditing(null);
  };

  useEffect(() => {
    if (currentModal && menus) {
      let newMenus = valuesOrdered(menus);
      setList(newMenus);
    }
  }, [menus, currentModal]);

  const title = (
    <div className="dashboard-modal-form-title">
      <h2>Edit Menus</h2>
      <span>Add new menus to this restaurant</span>
    </div>
  );

  const footer = (
    <div className="dashboard-modal-form-footer">
      <CustomButton
        key="back"
        type="secondary"
        className="btn-text"
        onClick={closeModal}
      >
        Close
      </CustomButton>
    </div>
  );

  return (
    <Modal
      visible={visible}
      onOk={closeModal}
      onCancel={closeModal}
      footer={footer}
      title={title}
      centered={true}
      wrapClassName={"edit-modal"}
      bodyStyle={{
        padding: 0
      }}
    >
      {visible && (
        <div className="edit-modal-container">
          <div className="edit-modal-row-header border-b">
            <h3>Menus</h3>
            <CustomButton
              type="primary"
              className="btn-text"
              style={{ marginLeft: "auto", marginRight: 8 }}
              onClick={() => {
                updateMenusList(reverse(list));
              }}
            >
              Reverse
            </CustomButton>
            <CustomButton
              type="primary"
              className="btn-text"
              onClick={() => {
                let id = "new" + Math.random();

                setList([
                  ...list,
                  {
                    id,
                    name: "",
                    children: []
                  }
                ]);
                setEditing(id);
              }}
            >
              Add New
            </CustomButton>
          </div>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {list.map((menu, index) => (
                    <Draggable
                      key={menu.id}
                      draggableId={menu.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          className="edit-modal-item border-b"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {editing === menu.id ? (
                            <Input
                              type="text"
                              onChange={onChange}
                              value={menu.name}
                            />
                          ) : (
                            <span>{menu.name}</span>
                          )}

                          <Badge
                            count={menu.children.length}
                            style={{
                              backgroundColor: "#fff",
                              color: "#999",
                              boxShadow: "0 0 0 1px #d9d9d9 inset"
                            }}
                          />

                          {editing === menu.id ? (
                            <CustomButton
                              type="primary"
                              className="btn-text"
                              onClick={onSave}
                            >
                              Save
                            </CustomButton>
                          ) : (
                            <CustomButton
                              type="primary"
                              className="btn-text"
                              onClick={() => setEditing(menu.id)}
                            >
                              Edit
                            </CustomButton>
                          )}

                          <CustomButton
                            type="tertiary"
                            className="btn-icon"
                            onClick={() => {
                              let newMenus = list.map(el => {
                                if (el.id === menu.id) el.delete = true;
                                return el;
                              });

                              dispatch({
                                type: "MENUS_SET_MENUS",
                                payload: newMenus
                              });

                              setList(newMenus);
                            }}
                          >
                            <Icon type="close" />
                          </CustomButton>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = state => ({
  modalType: state.layout.modalType,
  menus: state.menu.menus
});

export default connect(
  mapStateToProps,
  { setLayoutModal }
)(EditMenusModal);
