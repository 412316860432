import {
  SET_CURRENT_USER,
  SET_PROFILE,
  SET_FAVORITE_ITEM
} from "../actions/types";

const initialState = {
  isAuthenticated: false,
  user: {},
  currentProfile: {},
  favorites: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload
      };

    case SET_PROFILE:
      return {
        ...state,
        currentProfile: action.payload.profile,
        favorites: action.payload.favorites
      };

    case SET_FAVORITE_ITEM:
      const { type, cuisine, id } = action.payload;
      let newFavorites = { ...state.favorites };

      if (!newFavorites[cuisine.slug]) newFavorites[cuisine.slug] = cuisine;
      if (!newFavorites[cuisine.slug].restaurants)
        newFavorites[cuisine.slug].restaurants = {};

      newFavorites[cuisine.slug].restaurants[id] = action.payload;

      let favorites = {};

      newFavorites = Object.values(newFavorites).sort(
        (a, b) => a.slug < b.slug
      );

      for (let favorite of newFavorites) {
        favorites[favorite.slug] = favorite;
      }

      return {
        ...state,
        favorites
      };

    default:
      return state;
  }
}
