import React, { Component } from "react";
import { connect } from "react-redux";
import {
  searchCuisines,
  addRestaurantsToCuisines,
  updateCuisines
} from "../../../../actions/cuisineActions";
import PropTypes from "prop-types";
import { Select, List, Skeleton } from "antd";
import getIsOpenFromSchedule from "../../../../utils/getIsOpenFromSchedule";

import RestaurantItem from "./RestaurantItem";
import CustomButton from "../../../common/CustomButton";

import { filterCuisines } from "../../../../utils/array";

class Restaurants extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initLoading: false,
      loading: false,
      cuisines: [],
      favorites: {}
    };
  }

  componentDidMount() {
    this.setState({
      initLoading: false,
      cuisines: this.props.cuisines,
      favorites: this.props.favorites
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cuisines !== this.props.cuisines) {
      this.setState({
        cuisines: this.props.cuisines,
        initLoading: false,
        loading: false
      });
    }

    if (this.props.cuisinesUpdated === "ADD_RESTAURANTS_TO_CUISINES") {
      this.setState({
        cuisines: this.props.cuisines,
        initLoading: false,
        loading: false
      });

      this.props.updateCuisines("none");
    }

    if (this.props.favorites !== prevProps.favorites) {
      this.setState({ favorites: this.props.favorites });
    }
  }

  onLoadMore = ({ cuisineId, restaurantPage }) => {
    this.setState({
      cuisines: this.state.cuisines.map(cuisine => {
        let loadingRestaurants;

        if (cuisine.id === cuisineId) {
          loadingRestaurants = cuisine.restaurants.concat(
            [...new Array(1)].map(() => ({ loading: true }))
          );
        } else {
          loadingRestaurants = cuisine.restaurants;
        }

        return { ...cuisine, restaurants: loadingRestaurants };
      }),
      loading: true
    });

    this.props.addRestaurantsToCuisines({
      cuisineId,
      restaurantPage
    });
  };

  render() {
    const { initLoading, loading, cuisines, favorites } = this.state;
    const { checkedCuisines } = this.props;

    const loadMore = !initLoading && !loading;

    return (
      <div className="restaurant-type-list">
        {filterCuisines(cuisines, checkedCuisines).map((cuisine, id) => {
          const newPage = parseInt(cuisine.restaurant_page) + 1;

          let cuisineLoadMore;
          if (loadMore && newPage - 1 < cuisine.restaurant_pages) {
            cuisineLoadMore = (
              <div className="load-more">
                <CustomButton
                  type="tertiary"
                  onClick={() =>
                    this.onLoadMore({
                      cuisineId: cuisine.id,
                      restaurantPage: newPage
                    })
                  }
                  className="btn-text"
                >
                  {`Show All ${cuisine.name} Locations`}
                </CustomButton>
              </div>
            );
          } else {
            cuisineLoadMore = "";
          }

          return (
            <div key={cuisine.slug}>
              <h4 className="restaurant-type-title border-x-lr">
                {cuisine.name}
              </h4>
              <List
                loading={initLoading}
                loadMore={cuisineLoadMore}
                dataSource={cuisine.restaurants}
                itemLayout="horizontal"
                renderItem={restaurant => {
                  return (
                    <List.Item style={{ border: "none", padding: 0 }}>
                      <RestaurantItem
                        restaurant={restaurant}
                        key={restaurant.id}
                        favorite={
                          !!(
                            favorites[cuisine.slug] &&
                            favorites[cuisine.slug].restaurants[
                              restaurant.id
                            ] &&
                            favorites[cuisine.slug].restaurants[restaurant.id]
                              .active
                          )
                        }
                        schedule={restaurant.open_schedule}
                      />
                    </List.Item>
                  );
                }}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

Restaurants.propTypes = {
  cuisines: PropTypes.array,
  cuisinesUpdated: PropTypes.string,
  checkedCuisines: PropTypes.object,
  favorites: PropTypes.object
};

const mapStateToProps = state => ({
  cuisines: state.cuisine.cuisines,
  cuisinesUpdated: state.cuisine.cuisinesUpdated,
  favorites: state.auth.favorites
});

export default connect(
  mapStateToProps,
  { searchCuisines, addRestaurantsToCuisines, updateCuisines }
)(Restaurants);
