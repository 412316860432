import axios from "axios";
import React from "react";
import { MENUS_SET_ALL, SET_LOADING } from "./types";
import { prepareInformation } from "../utils/array";
import { notification } from "antd";

const openNotification = ({ message, description }) => {
  notification.open({
    message,
    description
  });
};

export const getRestaurantMenu = ({ restaurantSlug }) => async dispatch => {
  dispatch({
    type: SET_LOADING,
    payload: {
      loading: true,
      loadingType: "gettingMenus"
    }
  });

  const response = await axios.get(
    `/api/v1/restaurants/${restaurantSlug}/menus`
  );

  dispatch({
    type: MENUS_SET_ALL,
    payload: response.data
  });
  dispatch({
    type: SET_LOADING,
    payload: {
      loading: false,
      loadingType: ""
    }
  });
};

export const updateMenus = ({
  restaurantSlug,
  menus,
  categories,
  items,
  options
}) => async dispatch => {
  dispatch({
    type: SET_LOADING,
    payload: {
      loading: true,
      loadingType: "uploadingMenus"
    }
  });

  const validatedMenus = prepareInformation(menus, "menu");

  const validatedCategories = prepareInformation(categories, "category");

  const validatedItems = prepareInformation(items, "item");

  const validatedOptions = prepareInformation(options, "option");

  const generalErrors = [
    ...validatedMenus.errors,
    ...validatedCategories.errors,
    ...validatedItems.errors,
    ...validatedOptions.errors
  ];

  if (generalErrors.length) {
    openNotification({
      message: "There're some problems in your menus",
      description: (
        <ul>
          {generalErrors.map(el => (
            <li>{el}</li>
          ))}
        </ul>
      )
    });
  }

  try {
    const request = {
      menus: validatedMenus.result,
      categories: validatedCategories.result,
      items: validatedItems.result,
      options: validatedOptions.result
    };

    console.log(request);

    const response = await axios.put(
      `/api/v1/restaurants/${restaurantSlug}/menus`,
      { params: request }
    );

    console.log(response);
  } catch (e) {
    console.log(e.response.data);
  } finally {
    dispatch({
      type: SET_LOADING,
      payload: {
        loading: false,
        loadingType: ""
      }
    });
  }
};
