import axios from "axios";
import { SET_CUISINES, ADD_RESTAURANTS_TO_CUISINES, UPDATE_CUISINES } from "./types";

export const getCuisines = () => async dispatch => {
  try {
    const response = await axios.get("/api/v1/cuisines");
    dispatch({
      type: SET_CUISINES,
      payload: response.data.cuisines
    });
  } catch (e) {
    console.log(e);
  }
};

export const searchCuisines = () => async dispatch => {
  try {
    const response = await axios.get("/api/v1/cuisines/search", {
      params: {
        restaurants_per_page: 3
      }
    });
    dispatch({
      type: SET_CUISINES,
      payload: response.data.cuisines
    });
  } catch (e) {
    console.log(e);
  }
};

export const addRestaurantsToCuisines = request => async dispatch => {
  const { cuisineId, restaurantPage } = request;

  console.log(restaurantPage);

  try {
    const response = await axios.get(`/api/v1/cuisines/${cuisineId}`, {
      params: {
        restaurants_per_page: 3,
        restaurant_page: restaurantPage
      }
    });

    dispatch({
      type: ADD_RESTAURANTS_TO_CUISINES,
      payload: {
        cuisine: response.data.cuisine
      }
    });
  } catch (e) {
    console.log(e);
  }
};

export const updateCuisines = updateType => dispatch =>
  dispatch({
    type: UPDATE_CUISINES,
    payload: updateType
  });
