import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import { connect } from "react-redux";
import { registerUser } from "../../../actions/authActions";
import { resetErrors } from "../../../actions/errorActions";
import { resetLoading } from "../../../actions/layoutActions";
import PropTypes from "prop-types";

import { Form, Icon, Input, Checkbox } from "antd";
import CustomButton from "../../common/CustomButton";

const usernameRegex = new RegExp(
  "^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){3,18}[a-zA-Z0-9]$",
  "g"
);

class AuthModalSignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      loading: false
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.errorType === "register") {
      this.setState(
        {
          errors: this.props.errors
        },
        () => this.props.form.validateFieldsAndScroll({ force: true })
      );
      this.props.resetErrors();
    }

    if (this.props.loadingType === "register") {
      this.setState({
        loading: this.props.loading
      });
      this.props.resetLoading();
    }
  }

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  serverErrors = (rule, value, callback) => {
    if (this.state.errors[rule.field]) {
      callback(this.state.errors[rule.field][0]);

      const errors = this.state.errors;
      delete errors[rule.field];

      this.setState({
        errors
      });
    } else {
      callback();
    }
  };

  handleSubmit = e => {
    e.preventDefault();

    if (this.loading) return;

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.registerUser({
          username: values.username,
          email: values.email,
          password: values.password
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <>
        <div className="title">
          <Icon type="heart" />
          <h3>Join Menusite</h3>
        </div>
        <Form onSubmit={this.handleSubmit} className="auth-form auth-modal-form-signup">
          <Form.Item className="auth-form-item">
            {getFieldDecorator("username", {
              rules: [
                { required: true, message: "Please input your username!" },
                {
                  pattern: usernameRegex,
                  message:
                    "The username must have only alphanumeric characters and be between 5 and 18  "
                },
                {
                  validator: this.serverErrors,
                  force: true
                }
              ]
            })(
              <Input
                prefix={<Icon type="user" style={{ color: "rgb(1, 102, 164)" }} />}
                placeholder="Username"
                className="outline-input"
              />
            )}
          </Form.Item>
          <Form.Item className="auth-form-item">
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "Please input your Password!" },
                {
                  validator: this.serverErrors,
                  force: true
                }
              ]
            })(
              <Input
                prefix={
                  <Icon
                    type="lock"
                    theme="filled"
                    style={{ color: "rgb(1, 102, 164)" }}
                  />
                }
                type="password"
                placeholder="Password"
                className="outline-input"
              />
            )}
          </Form.Item>
          <Form.Item className="auth-form-item">
            {getFieldDecorator("confirm", {
              rules: [
                { required: true, message: "Please confirm your password!" },
                {
                  validator: this.compareToFirstPassword
                },
                {
                  validator: this.serverErrors,
                  force: true
                }
              ]
            })(
              <Input
                prefix={
                  <Icon
                    type="lock"
                    theme="filled"
                    style={{ color: "rgb(1, 102, 164)" }}
                  />
                }
                type="password"
                placeholder="Confirm Password"
                className="outline-input"
              />
            )}
          </Form.Item>
          <Form.Item className="auth-form-item">
            {getFieldDecorator("email", {
              rules: [
                { type: "email", message: "The input is not a valid E-mail" },
                { required: true, message: "Please input your email address!" },
                {
                  validator: this.serverErrors,
                  force: true
                }
              ]
            })(
              <Input
                prefix={<Icon type="mail" style={{ color: "rgb(1, 102, 164)" }} />}
                placeholder="Email Address"
                className="outline-input"
              />
            )}
          </Form.Item>
          <Form.Item className="auth-form-item buttons-container">
            <CustomButton type="primary" htmlType="submit" loading={this.state.loading}>
              Sign up
            </CustomButton>
            <CustomButton
              type="secondary"
              onClick={() => this.props.changeModal("login")}
            >
              Back to Login
            </CustomButton>
          </Form.Item>

          <Form.Item className="auth-form-item back-to-login">
            <span>
              <small onClick={() => this.props.changeModal("login")}>Login</small>
            </span>
          </Form.Item>
        </Form>
      </>
    );
  }
}

AuthModalSignUp.propTypes = {
  errors: PropTypes.object,
  errorType: PropTypes.string,
  loading: PropTypes.bool,
  loadingType: PropTypes.string
};

const WrappedAuthModalSignUp = withRouter(
  Form.create({ name: "normal_signup" })(AuthModalSignUp)
);

const mapStateToProps = state => ({
  errors: state.error.errors,
  errorType: state.error.errorType,
  loading: state.layout.loading,
  loadingType: state.layout.loadingType
});

export default connect(
  mapStateToProps,
  { registerUser, resetErrors, resetLoading }
)(WrappedAuthModalSignUp);
