import React, { Component } from "react";

import { connect } from "react-redux";
import { setAuthModal } from "../../../actions/layoutActions";
import PropTypes from "prop-types";

import IconCustom from "../../common/IconCustom";
import AuthModalLogin from "./AuthModalLogin";
import AuthModalSignUp from "./AuthModalSignUp";
import "./AuthModal.less";

class AuthModal extends Component {
  constructor(props) {
    super(props);
    console.log(props.modal);

    this.state = {
      modal: props.modal
    };
  }

  componentDidMount() {
    this.props.setAuthModal(this.state.modal);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.authModal !== this.props.authModal && this.props.authModal === "none") {
      this.props.closeModal();
      return;
    }

    if (prevProps.modalVisible !== this.props.modalVisible && this.props.modalVisible) {
      this.changeModal(this.props.modal);
    }

    if (prevProps.authModal !== this.props.authModal) {
      this.changeModal(this.props.authModal);
    }
  }

  changeModal = modal => {
    this.setState({
      modal
    });
    this.props.setAuthModal(modal);
  };

  render() {
    const { modal } = this.state;
    let $modal;

    if (modal === "login") {
      $modal = <AuthModalLogin changeModal={this.changeModal} />;
    } else if (modal === "signup") {
      $modal = <AuthModalSignUp changeModal={this.changeModal} />;
    } else if (modal === "none") {
    }

    return (
      <div className="auth-modal-content">
        <div className="auth-modal-header">
          <IconCustom icon="menusite-chef-simple" />
          <h2>
            MENU<span className="text-blue">SITE</span>
          </h2>
        </div>
        <div className="auth-modal-container">
          <h4>BECAUSE YOU'RE HUNGRY</h4>
          {$modal}
        </div>
        <div className="auth-modal-footer">Legal</div>
        <div className="auth-modal-background" />
      </div>
    );
  }
}

AuthModal.propTypes = {
  setAuthModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  authModal: PropTypes.string,
  modal: PropTypes.string,
  modalVisible: PropTypes.bool
};

const mapStateToProps = state => ({
  authModal: state.layout.authModal
});

export default connect(
  mapStateToProps,
  { setAuthModal }
)(AuthModal);
