import React, { useState } from "react";

import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import { logoutUser } from "../../actions/authActions";

import { Icon } from "antd";
import IconCustom from "../common/IconCustom";
import ProfileSettings from "./ProfileSettings";

import "./ProfileDropdown.less";

const ProfileDropdown = props => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const handleOk = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onClickFavorites = () => {
    props.history.push({
      pathname: "/",
      search: "?tab=favourites"
    });
  };

  return (
    <div id="profile-dropdown" className={props.className}>
      <div className="dropdown-row border-b" onClick={onClickFavorites}>
        <IconCustom icon="noun-hearth" />
        <span className="text-content">Favourites</span>
      </div>
      {/*      <div className="dropdown-row border-b" onClick={handleOk}>
        <IconCustom icon="cog-solid" />
        <span className="text-content">Settings</span>
      </div>
         */}

      <div className="dropdown-row" onClick={() => dispatch(logoutUser())}>
        <span className="text-content">Logout</span>
      </div>
      <ProfileSettings
        visible={visible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <div className="close-mobile-menu" onClick={props.handleMobileMenu}>
        <Icon type="close" />
      </div>
    </div>
  );
};

export default withRouter(ProfileDropdown);
