import React, { useState, useEffect, useRef } from "react";

import { connect, useDispatch } from "react-redux";
import { setLayoutModal } from "../../../actions/layoutActions";

import { Modal, Badge, Input, Icon, Collapse } from "antd";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import CustomButton from "../../common/CustomButton";
import { reorder, reverse, orderArrayFromPosition } from "../../../utils/array";
import EditItemsModalOptions from "./EditItemsModalOptions";

import classNames from "classnames";

const getItemStyle = (isDragging, draggableStyle) => ({
  borderTop: isDragging ? "solid 1px #eaeaea" : "none",
  ...draggableStyle
});

const EditMenuModal = ({
  modalType,
  menus,
  categories,
  setLayoutModal,
  items,
  options,
  ...rest
}) => {
  const [itemList, setItemList] = useState([]);
  const [editing, setEditing] = useState(null);
  const [collapsed, setCollapsed] = useState(null);
  const currentModal = modalType["MODAL_MENU_ITEMS"];
  const visible = !!currentModal;
  const dispatch = useDispatch();
  const itemsContent = useRef();

  const closeModal = () => {
    setLayoutModal({
      type: "MODAL_MENU_ITEMS",
      id: currentModal.id,
      delete: true
    });
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // Update categories children
    let newCategory = { ...categories[currentModal.id] };

    const newChildrenOrder = reorder(
      newCategory.children,
      result.source.index,
      result.destination.index
    );

    newCategory.children = newChildrenOrder;

    dispatch({
      type: "MENUS_SET_CATEGORIES",
      payload: [newCategory]
    });

    // Update items
    const newItems = reorder(
      itemList,
      result.source.index,
      result.destination.index
    );

    dispatch({
      type: "MENUS_SET_ITEMS",
      payload: newItems
    });

    // Update locally
    setItemList(newItems);
  };

  const updateItemList = newItems => {
    newItems = orderArrayFromPosition(newItems);
    const newCategory = { ...categories[currentModal.id] };
    newCategory.children = newItems.map(el => el.id);

    dispatch({
      type: "MENUS_SET_CATEGORIES",
      payload: [newCategory]
    });

    dispatch({
      type: "MENUS_SET_ITEMS",
      payload: newItems
    });

    // Update locally
    setItemList(newItems);
  };

  const onChange = e => {
    const newItemList = [...itemList];
    const itemEditing = itemList.findIndex(el => el.id === editing);

    if (itemEditing === -1) return;

    newItemList[itemEditing][e.target.name] = e.target.value;

    setItemList(newItemList);
  };

  const onEdit = id => {
    const itemEditing = itemList.findIndex(el => el.id === editing);

    if (editing && itemEditing !== -1) {
      dispatch({
        type: "MENUS_SET_ITEMS",
        payload: [itemList[itemEditing]]
      });
    }

    setEditing(id);
    setCollapsed(id);
  };

  const onSave = () => {
    const itemEditing = itemList.findIndex(el => el.id === editing);

    if (itemEditing === -1) return;

    dispatch({
      type: "MENUS_SET_ITEMS",
      payload: [itemList[itemEditing]]
    });

    setEditing(null);
  };

  useEffect(() => {
    if (currentModal && categories[currentModal.id])
      setItemList(categories[currentModal.id].children.map(id => items[id]));
  }, [categories, items, options, modalType]);

  const title = (
    <div className="dashboard-modal-form-title">
      <h2>Edit Items</h2>
      <span>Add new items to this category</span>
    </div>
  );

  const footer = (
    <div className="dashboard-modal-form-footer">
      <CustomButton
        key="back"
        type="secondary"
        className="btn-text"
        onClick={closeModal}
      >
        Close
      </CustomButton>
    </div>
  );

  return (
    <Modal
      visible={visible}
      onOk={closeModal}
      onCancel={closeModal}
      footer={footer}
      title={title}
      centered={true}
      wrapClassName={classNames("edit-modal", {
        "edit-modal-2":
          modalType["MODAL_MENU_CATEGORIES"] && modalType["MODAL_MENU_ITEMS"]
      })}
      mask={
        !modalType["MODAL_MENU_CATEGORIES"] && modalType["MODAL_MENU_ITEMS"]
      }
      bodyStyle={{
        padding: 0
      }}
    >
      {visible && (
        <div className="edit-modal-container">
          <div className="edit-modal-row-header border-b">
            <h3>
              {menus[categories[currentModal.id].menu_id].name}
              {" - "}
              {categories[currentModal.id].name}
            </h3>
            <CustomButton
              type="primary"
              className="btn-text"
              style={{ marginLeft: "auto", marginRight: 8 }}
              onClick={() => {
                updateItemList(reverse(itemList));
              }}
            >
              Reverse
            </CustomButton>
            <CustomButton
              type="primary"
              className="btn-text"
              onClick={() => {
                let id = "new" + Date.now();

                dispatch({
                  type: "MENUS_SET_ITEMS",
                  payload: [
                    {
                      id,
                      menu_category_id: currentModal.id,
                      name: "",
                      description: "",
                      children: []
                    }
                  ]
                });
                setEditing(id);
                setCollapsed(id);
              }}
            >
              Add New
            </CustomButton>
          </div>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  style={{ height: 500, overflowY: "scroll" }}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {orderArrayFromPosition(itemList).map((item, index) => {
                    if (!item);

                    return (
                      <>
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => {
                            // Custom mouse down function
                            provided.dragHandleProps.onMouseDown = (function() {
                              const cached_function =
                                provided.dragHandleProps.onMouseDown;

                              return function(e) {
                                if (collapsed === item.id) setCollapsed(null);

                                return cached_function(e);
                              };
                            })();

                            return (
                              <div
                                className="edit-modal-items-item border-b"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <div
                                  className="collapse-icon"
                                  onMouseDown={e => e.stopPropagation()}
                                  onClick={e =>
                                    setCollapsed(
                                      collapsed === item.id ? null : item.id
                                    )
                                  }
                                >
                                  <Icon
                                    type="caret-right"
                                    rotate={collapsed === item.id ? 90 : 0}
                                  />
                                </div>

                                <div className="edit-modal-items-header-name">
                                  {editing === item.id ? (
                                    <Input
                                      type="text"
                                      name="name"
                                      onChange={onChange}
                                      value={item.name}
                                      onMouseDown={e => e.stopPropagation()}
                                    />
                                  ) : (
                                    <span>{item.name}</span>
                                  )}
                                </div>

                                {item.children && (
                                  <Badge
                                    count={item.children.length}
                                    style={{
                                      backgroundColor: "#fff",
                                      color: "#999",
                                      boxShadow: "0 0 0 1px #d9d9d9 inset"
                                    }}
                                  />
                                )}

                                {editing === item.id ? (
                                  <CustomButton
                                    type="primary"
                                    className="btn-text"
                                    onClick={onSave}
                                    onMouseDown={e => e.stopPropagation()}
                                  >
                                    Save
                                  </CustomButton>
                                ) : (
                                  <CustomButton
                                    type="primary"
                                    className="btn-text"
                                    onClick={() => onEdit(item.id)}
                                    onMouseDown={e => e.stopPropagation()}
                                  >
                                    Edit
                                  </CustomButton>
                                )}

                                <CustomButton
                                  type="tertiary"
                                  className="btn-icon"
                                  onClick={() => {
                                    let newItems = itemList.map(el => {
                                      if (el.id === item.id) el.delete = true;
                                      return el;
                                    });

                                    dispatch({
                                      type: "MENUS_SET_ITEMS",
                                      payload: newItems
                                    });
                                  }}
                                >
                                  <Icon type="close" />
                                </CustomButton>
                              </div>
                            );
                          }}
                        </Draggable>
                        {collapsed === item.id && (
                          <div
                            className="edit-modal-items-content"
                            ref={itemsContent}
                          >
                            <div className="edit-modal-items-description border-b">
                              {editing === item.id ? (
                                <Input.TextArea
                                  name="description"
                                  rows={4}
                                  onChange={onChange}
                                  value={item.description}
                                />
                              ) : (
                                item.description
                              )}
                            </div>
                            <div className="edit-modal-items-header border-b">
                              <span>Option</span>
                              <span>Price</span>
                              <CustomButton
                                type="primary"
                                className="btn-text"
                                onClick={() => {
                                  let id = "new" + Date.now();

                                  dispatch({
                                    type: "MENUS_SET_OPTIONS",
                                    payload: [
                                      {
                                        id,
                                        menu_item_id: item.id,
                                        name: "",
                                        price: ""
                                      }
                                    ]
                                  });
                                }}
                              >
                                Add new
                              </CustomButton>
                            </div>
                            <EditItemsModalOptions parentItem={item} />
                          </div>
                        )}
                      </>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = state => ({
  modalType: state.layout.modalType,
  menus: state.menu.menus,
  categories: state.menu.categories,
  items: state.menu.items,
  options: state.menu.options
});

export default connect(
  mapStateToProps,
  { setLayoutModal }
)(EditMenuModal);
