import cloneDeep from "lodash.clonedeep";

import {
  GET_RESTAURANTS,
  GET_RESTAURANT,
  SET_RESTAURANT_UPDATE_TYPE,
  SET_RESTAURANT_SEARCH_RESULT,
  UPDATE_RESTAURANT_MENUS,
  RESTAURANTS_SET_SELECTED,
  RESTAURANTS_SET_RESTAURANTFORM,
  RESTAURANTS_UPDATE_RESTAURANT
} from "../actions/types";

const baseRestaurant = {
  name: "",
  featured_listing: 0,
  listing_status: "Online",
  restaurant_branches: [
    {
      phone: "",
      location: {
        address: "",
        city: "",
        postal_code: ""
      }
    }
  ],
  cuisines: [],
  socials: []
};

const initialState = {
  restaurantUpdated: "none",
  restaurants: [],
  restaurant: {},
  restaurantForm: cloneDeep(baseRestaurant),
  restaurantSearch: [],
  selected: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_RESTAURANTS: {
      return {
        ...state,
        restaurants: action.payload,
        restaurantUpdated: GET_RESTAURANTS
      };
    }
    case GET_RESTAURANT: {
      return {
        ...state,
        restaurant: action.payload,
        restaurantUpdated: GET_RESTAURANT
      };
    }
    case SET_RESTAURANT_UPDATE_TYPE: {
      return {
        ...state,
        restaurantUpdated: action.payload
      };
    }
    case SET_RESTAURANT_SEARCH_RESULT: {
      return {
        ...state,
        restaurantSearch: action.payload
      };
    }
    case UPDATE_RESTAURANT_MENUS: {
      let restaurant = cloneDeep(action.payload);

      return {
        ...state,
        restaurant
      };
    }
    case RESTAURANTS_SET_SELECTED: {
      const { id } = action;
      let restaurant = {};

      if (id) {
        restaurant = state.restaurants.find(el => el.id === id);
      } else {
        restaurant = cloneDeep(baseRestaurant);
      }

      return {
        ...state,
        restaurantForm: restaurant,
        selected: id || null
      };
    }
    case RESTAURANTS_SET_RESTAURANTFORM: {
      const { restaurant } = action;

      return {
        ...state,
        restaurantForm: restaurant
      };
    }
    case RESTAURANTS_UPDATE_RESTAURANT: {
      const { restaurant } = action;
      const newRestaurants = cloneDeep([...state.restaurants]);

      const index = state.restaurants.findIndex(el => el.id === restaurant.id);
      if (index === -1) {
        newRestaurants.push(restaurant);
      } else {
        console.log(index);
        newRestaurants[index] = restaurant;
      }

      return {
        ...state,
        restaurants: newRestaurants
      };
    }
    default:
      return state;
  }
}
