import { SET_ERRORS, RESET_ERRORS } from "../actions/types";

const initialState = {
  errors: {},
  errorType: "none"
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ERRORS:
      const { errors, errorType } = action.payload;

      return {
        ...state,
        errors,
        errorType
      };

    case RESET_ERRORS:
      return initialState;
    default:
      return state;
  }
}
