import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../actions/authActions";

import { Modal, Input, Form, Icon, Select } from "antd";
import CustomButton from "../common/CustomButton";

import "./ProfileSettings.less";

const ProfileSettings = ({ visible, handleOk, handleCancel, ...props }) => {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.auth.currentProfile);
  const user = useSelector(state => state.auth.user);

  const [values, setValues] = useState({
    name: profile.name || "",
    username: user.username || "",
    email: user.email || "",
    homePhone: profile.homePhone || "",
    mobilePhone: profile.mobilePhone || ""
  });

  useEffect(() => {
    const newValues = { ...values };

    if (user.email && user.email !== values.email) {
      newValues["email"] = user.email;
    }
    if (user.username && user.username !== values.username) {
      newValues["username"] = user.username;
    }
    if (profile.name && profile.name !== values.name) {
      newValues["name"] = profile.name;
    }
    if (profile.homePhone && profile.homePhone !== values.homePhone) {
      newValues["homePhone"] = profile.homePhone;
    }
    if (profile.mobilePhone && profile.mobilePhone !== values.mobilePhone) {
      newValues["mobilePhone"] = profile.mobilePhone;
    }
  }, [profile, user]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  return (
    <Modal
      visible={visible}
      title="Title"
      onOk={handleOk}
      onCancel={handleCancel}
      centered={true}
      wrapClassName="profile-modal"
      title={
        <div className="profile-modal-header">
          <h4>Settings</h4>
          <span>Update your info here</span>
        </div>
      }
      width={850}
      bodyStyle={{
        padding: 0
      }}
      footer={
        <div className="profile-modal-footer">
          <CustomButton
            key="back"
            type="tertiary"
            onClick={handleCancel}
            className="btn-text"
          >
            Don't Save & Close
          </CustomButton>

          <CustomButton
            key="submit"
            type="primary"
            onClick={handleOk}
            className="btn-text"
          >
            Save
          </CustomButton>
        </div>
      }
    >
      <Form layout="vertical" className="profile-modal-form">
        <div className="row profile-info-row border-b">
          <Form.Item label="Profile Photo" className="item">
            <img src="/static_assets/img/avatar.png" alt="avatar" className="avatar" />
            <div className="profile-info">
              <span>Matt A. Myers</span>
              <span>@{values.username}</span>
            </div>
            <CustomButton type="primary" className="btn-upload">
              Upload Photo
            </CustomButton>
          </Form.Item>
        </div>

        <div className="row border-b">
          <Form.Item label="Display Name" className="item">
            <Input
              type="text"
              name="name"
              placeholder="Display Name"
              value={values.name}
              onChange={handleInputChange}
            />
          </Form.Item>
          <Form.Item label="Username" className="item">
            <Input
              type="text"
              name="username"
              placeholder="Username"
              value={values.username}
              onChange={handleInputChange}
            />
          </Form.Item>
        </div>

        <div className="row border-b">
          <Form.Item label="Email" className="item">
            <Input
              type="text"
              name="email"
              placeholder="Email"
              value={values.email}
              onChange={handleInputChange}
            />
          </Form.Item>
          <Form.Item label="Home Phone" className="item">
            <Input
              type="text"
              name="homePhone"
              placeholder="111-111-111"
              value={values.homePhone}
              onChange={handleInputChange}
            />
          </Form.Item>
          <Form.Item label="Mobile Phone" className="item">
            <Input
              type="text"
              name="mobilePhone"
              placeholder="111-111-111"
              value={values.mobilePhone}
              onChange={handleInputChange}
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default ProfileSettings;
