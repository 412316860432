import { combineReducers } from "redux";
import restaurantReducer from "./restaurantReducer";
import cuisineReducer from "./cuisineReducer";
import layoutReducer from "./layoutReducer";
import errorReducer from "./errorReducer";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import menuReducer from "./menuReducer";

export default combineReducers({
  restaurant: restaurantReducer,
  menu: menuReducer,
  cuisine: cuisineReducer,
  layout: layoutReducer,
  error: errorReducer,
  auth: authReducer,
  user: userReducer,
});
