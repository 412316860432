import { RESET_LOADING, SET_AUTH_MODAL, LAYOUT_SET_MODAL_TYPE } from "./types";

export const resetLoading = () => dispatch => {
  dispatch({
    type: RESET_LOADING
  });
};

export const setAuthModal = modal => dispatch => {
  dispatch({
    type: SET_AUTH_MODAL,
    payload: modal
  });
};

export const setLayoutModal = modal => dispatch => {
  dispatch({
    type: LAYOUT_SET_MODAL_TYPE,
    payload: modal
  });
};
