import axios from "axios";
import {
  GET_RESTAURANTS,
  GET_RESTAURANT,
  SET_RESTAURANT_UPDATE_TYPE,
  SET_RESTAURANT_SEARCH_RESULT,
  SET_LOADING,
  MENUS_SET_ALL,
  RESTAURANTS_SET_SELECTED,
  RESTAURANTS_SET_RESTAURANTFORM,
  RESTAURANTS_UPDATE_RESTAURANT
} from "./types";
import { reorder } from "../utils/array";
import { message } from "antd";

export const getRestaurants = () => async dispatch => {
  const response = await axios.get("/api/v1/restaurants");
  dispatch({
    type: GET_RESTAURANTS,
    payload: response.data.restaurants
  });
};

export const setRestaurant = restaurant => ({
  type: GET_RESTAURANT,
  payload: restaurant
});

export const setRestaurantForm = restaurant => ({
  type: RESTAURANTS_SET_RESTAURANTFORM,
  restaurant
});

export const setLoading = (loading, loadingType) => ({
  type: SET_LOADING,
  payload: {
    loading,
    loadingType
  }
});

export const getRestaurant = request => async dispatch => {
  const { restaurantSlug } = request;
  const response = await axios.get(`/api/v1/restaurants/${restaurantSlug}`);
  const restaurant = response.data.restaurant;

  dispatch(setRestaurant(restaurant));
};

export const searchRestaurant = query => async dispatch => {
  if (!query) {
    dispatch({
      type: SET_RESTAURANT_SEARCH_RESULT,
      payload: []
    });
    return;
  }

  dispatch({
    type: SET_LOADING,
    payload: {
      loading: true,
      loadingType: "searchingRestaurant"
    }
  });

  try {
    const response = await axios.get(`/api/v1/restaurants/search`, {
      params: { query }
    });

    dispatch({
      type: SET_RESTAURANT_SEARCH_RESULT,
      payload: response.data.q
    });
    dispatch({
      type: SET_LOADING,
      payload: {
        loading: false,
        loadingType: "searchingRestaurant"
      }
    });
  } catch (e) {
    console.log(e.response);
  }
};

export const getUserRestaurants = request => async dispatch => {
  const { username } = request;

  try {
    const response = await axios.get(`/api/v1/users/${username}/restaurants`);

    dispatch({
      type: GET_RESTAURANTS,
      payload: response.data.restaurants
    });
  } catch (e) {
    console.log(e.response);
  }
};

export const updateRestaurant = restaurant => async dispatch => {
  dispatch(setLoading(true, "updatingRestaurant"));

  try {
    let response;
    if (restaurant.id) {
      response = await axios.put(
        `/api/v1/restaurants/${restaurant.slug}`,
        restaurant
      );
    } else {
      response = await axios.post(`/api/v1/restaurants/`, restaurant);
    }

    if (!response.data.restaurant) {
      message.error("Something went wrong");
      throw response.data;
    }

    message.success("Restaurant successfully updated");
    dispatch({
      type: RESTAURANTS_UPDATE_RESTAURANT,
      restaurant: response.data.restaurant
    });
  } catch (e) {
    console.log(e);
  } finally {
    dispatch(setLoading(false, "updatingRestaurant"));
  }
};

export const updateSchedules = request => async dispatch => {
  dispatch({
    type: SET_LOADING,
    payload: {
      loading: true,
      loadingType: "updatingSchedules"
    }
  });

  try {
    const { schedules, restaurantBranchId } = request;

    const response = await axios.put(
      `/api/v1/restaurant_branches/${restaurantBranchId}/schedules`,
      {
        schedules
      }
    );
  } catch (e) {
    console.log(e.response);
  } finally {
    dispatch({
      type: SET_LOADING,
      payload: {
        loading: false,
        loadingType: "updatingSchedules"
      }
    });
  }
};

export const setRestaurantUpdateType = updateType => dispatch =>
  dispatch({
    type: SET_RESTAURANT_UPDATE_TYPE,
    payload: updateType
  });

export const setRestaurantMenus = ({
  menus,
  restaurantSlug
}) => async dispatch => {
  dispatch({
    type: SET_LOADING,
    payload: {
      loading: true,
      loadingType: "updatingRestaurantMenus"
    }
  });

  try {
    const response = await axios.put(
      `/api/v1/restaurants/${restaurantSlug}/menus`,
      {
        menus
      }
    );
    console.log(response);
  } catch (e) {
    console.log(e.response);
  } finally {
    dispatch({
      type: SET_LOADING,
      payload: {
        loading: false,
        loadingType: "updatingRestaurantMenus"
      }
    });
  }
};

export const selectRestaurant = id => ({
  type: RESTAURANTS_SET_SELECTED,
  id
});
