import {
  SET_CURRENT_USER,
  SET_PROFILE,
  USERS_SET_USERS
} from "../actions/types";

const initialState = {
  users: [],
  profiles: [],
  user: {},
  profile: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case USERS_SET_USERS: {
      const { users } = action.payload;
      return { ...state, users };
    }
    default:
      return state;
  }
}
