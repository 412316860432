import React, { useState, useEffect } from "react";

import { Input, Icon } from "antd";
import { connect, useDispatch } from "react-redux";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import CustomButton from "../../common/CustomButton";
import { reorder } from "../../../utils/array";

const getItemStyle = (isDragging, draggableStyle) => ({
  borderTop: isDragging ? "solid 1px #eaeaea" : "none",
  ...draggableStyle
});

const EditItemsModalOptions = ({ parentItem, items, options, ...rest }) => {
  const [optionsList, setOptionsList] = useState([]);
  const [editing, setEditing] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (parentItem && parentItem.children)
      setOptionsList(parentItem.children.map(el => options[el]));
  }, [parentItem, items, options]);

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // Update items
    const newItem = { ...parentItem };

    const newChildrenOrder = reorder(
      parentItem.children,
      result.source.index,
      result.destination.index
    );

    newItem.children = newChildrenOrder;

    dispatch({
      type: "MENUS_SET_ITEMS",
      payload: [newItem]
    });

    // Update options

    const newOptions = reorder(
      optionsList,
      result.source.index,
      result.destination.index
    );

    console.log(newOptions);

    dispatch({
      type: "MENUS_SET_OPTIONS",
      payload: newOptions
    });

    // Update locally
    setOptionsList(newOptions);
  };

  const onChange = e => {
    const newOptionsList = [...optionsList];
    const optionEditing = optionsList.findIndex(el => el.id === editing);

    if (optionEditing === -1) return;

    newOptionsList[optionEditing][e.target.name] = e.target.value;

    setOptionsList(newOptionsList);
  };

  const onEdit = id => {
    const optionEditing = optionsList.findIndex(el => el.id === editing);

    if (editing && optionEditing !== -1) {
      dispatch({
        type: "MENUS_SET_OPTIONS",
        payload: [optionsList[optionEditing]]
      });
    }

    setEditing(id);
  };

  const onSave = () => {
    const itemEditing = optionsList.findIndex(el => el.id === editing);

    if (itemEditing === -1) return;

    dispatch({
      type: "MENUS_SET_OPTIONS",
      payload: [optionsList[itemEditing]]
    });

    setEditing(null);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {optionsList.map((option, index) => {
              if (!option) return;

              return (
                <Draggable
                  key={option.id}
                  draggableId={option.id}
                  index={index}
                >
                  {(provided, snapshot) => {
                    return (
                      <div
                        className="edit-modal-items-option border-b"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {editing === option.id ? (
                          <>
                            <Input
                              type="text"
                              name="name"
                              onChange={onChange}
                              value={option.name}
                            />
                            <Input
                              type="text"
                              name="price"
                              onChange={onChange}
                              value={option.price}
                            />
                          </>
                        ) : (
                          <>
                            <span>{option.name}</span>
                            <span>{option.price}</span>
                          </>
                        )}

                        {editing === option.id ? (
                          <CustomButton
                            type="primary"
                            className="btn-text"
                            onClick={onSave}
                          >
                            Save
                          </CustomButton>
                        ) : (
                          <CustomButton
                            type="primary"
                            className="btn-text"
                            onClick={() => onEdit(option.id)}
                          >
                            Edit
                          </CustomButton>
                        )}

                        <CustomButton
                          type="tertiary"
                          className="btn-icon"
                          onClick={() => {
                            let newOptions = optionsList.map(el => {
                              if (el.id === option.id) el.delete = true;
                              return el;
                            });

                            dispatch({
                              type: "MENUS_SET_OPTIONS",
                              payload: newOptions
                            });
                          }}
                        >
                          <Icon type="close" />
                        </CustomButton>
                      </div>
                    );
                  }}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const mapStateToProps = state => ({
  options: state.menu.options,
  items: state.menu.items
});

export default connect(mapStateToProps)(EditItemsModalOptions);
